import { defineConstantObject } from "./helper";

/* eslint-disable max-len */
const STATES = {
  'IQL': 20,
  'MQL': 40,
  'SQL': 60,
  'POTENTIAL_CUSTOMER': 80,
  'CUSTOMER': 100,
  'SUSPEND': 120
};

const ROLES = [
  'marketing_manager',
  'marketer',
];
const CONTACT_COLUMN = [
  'name',
  'Website',
  'list',
  'Email',
  'phone',
  'contact_form_url',
  'unit',
  'PIC',
  'stage',
  'update_date',
  'created_date',
];

const REPORT_COLUMN = [
  'list_name',
  'total',
];

const CONTACT_FORM_COLUMN = [
  'name',
  'Website',
  'Email',
  'List',
  'contact_form_url',
  'PIC',
  'result',
  'update_date',
  'created_date',
];

const LIST_COLUMN = [
  'name',
  'size',
  'source',
  'unit',
  'update_date',
  'created_date',
];

const LIST_DETAIL_COLUMN = [
  'name',
  'Website',
  'Email',
  'type',
  'update_date',
  'created_date',
];

const ACTION = [
  'edit',
  'delete',
  'export',
  'move_blacklist',
  'update_PIC',
];

const ACTION_LIST = [
  'Edit',
  'Delete',
  'Export'
];

const ACTION_LIST_DETAIL = [
  'Export'
];

const ACTION_TEAM = [
  'Edit',
  'Delete'
]
const ACTION_CONTACT_FORM = [
  'Edit'
]

const ACTION_ORDERS = [
  'Edit',
]

const ACTION_INVOICES = [
  'Edit',
  'Delete',
  'Change Status to Sent',
]

const ACTION_BLACK_LIST = [
  'edit',
  'export',
  'remove_blacklist',
  'update_PIC',
];

const STAGE_OPTION = [
  'IQL',
  'MQL',
  'SQL',
  'Potential customer',
  'Customer',
  'Suspend',
];

const RESULT_OPTION = [
  'TODO',
  'SENT',
  'UNSENT'
];

const STAGE_OBJECT = {
  'iql': 'IQL',
  'mql': 'MQL',
  'sql': 'SQL',
  'potential_customer': 'Potential customer',
  'customer': 'Customer',
  'suspend': 'Suspend',
};

const UNIT_OBJECT = {
  'sfs': 'SFS',
  'jmu': 'JMU',
  'emu': 'EMU',
  'huo': 'HUO',
  'ets': 'ETS',
};

const TYPE_CONTACT = [
  'Individual',
  'Company'
];

const TYPE_CONTACT_OBJECT = {
  'individual': 'Individual',
  'company': 'Company',
};

const FILLTER_CATEGORY = [
  'stages',
  'units',
  'teams',
  'pic',
];

const CONTACT_FIELD_CREATE = [
  { label: 'name', type: 'text', required: true, max: 255 },
  {
    label: 'type',
    type: 'select',
    required: true,
    max: 255,
    options: [
      { value: 'company', label: 'Company' },
      { value: 'individual', label: 'Individual' },
    ],
  },
  { label: 'Website', type: 'text', max: 255, },
  { label: 'Email', type: 'text', max: 255, },
  { label: 'contact_form_url', name: "contact_form_url", type: 'text', max: 1000, },
  { label: 'phone', type: 'text' },
  { label: 'country', type: 'select', max: 255, },
  { label: 'city', type: 'select', max: 255, },
  { label: 'address', type: 'text', max: 255, },
  { label: 'industry', type: 'select', max: 255, },
  {
    label: 'stage', type: 'select',
    options: [
      { value: 'iql', label: 'IQL' },
      { value: 'mql', label: 'MQL' },
      { value: 'sql', label: 'SQL' },
      { value: 'potential customer', label: 'Potential customer' },
      { value: 'customer', label: 'Customer' },
      { value: 'suspend', label: 'Suspend' },
    ]
  },
  { label: 'MQL Date', type: 'date', max: 255 },
  { label: 'SQL Date', type: 'date', max: 255 },
  {
    label: 'unit', type: 'select', required: true,
    options: []
  },
  { label: 'team', type: 'select', max: 255, },
  { label: 'channel', type: 'select', max: 255, },
  { label: 'marketer', type: 'select', max: 255, },
  { label: 'saler', type: 'select', max: 255, },
  { label: 'AM', type: 'select', max: 255, },
  { label: 'company_classification', name: "company_classification", type: 'select', max: 255, },
];

const LIST_FIELD_CREATE = [
  { label: 'Name', type: 'text', required: true },
  {
    label: 'Size',
    type: 'text',
    required: true,
  },
  { label: 'Source', type: 'text' },
  { label: 'Unit', type: 'text' },
];

const CONTACT_TO_LIST_FIELD_CREATE = [
  { label: 'list_name', type: 'text', required: true, max: 255 },
  {
    label: 'unit', type: 'select', required: true,
    options: []
  },
  { label: 'source', type: 'text', max: 255 },
];

const GET_CONTACT_FIELD = [
  { label: 'list', type: 'select', required: true },
  { label: 'input_number', type: 'text', required: true },
];

const MAX_IMPORT_FILE_SIZE = 100; //MB

const ADD_CONTACT_POINT_FIELD = [
  {
    label: 'full_name',
    type: 'text',
    id: 'full_name',
    rules: {
      max: 255,
      required: true
    }
  },
  {
    label: 'phone',
    type: 'text',
    id: 'phone',
    rules: {
      max: 255,
    }
  },
  {
    label: 'Email',
    type: 'text',
    id: 'email',
    rules: {
      max: 255,
    }
  },
  {
    label: 'job_position',
    type: 'text',
    id: 'job_position',
    rules: {
      max: 255,
    }
  },
  {
    label: 'address',
    type: 'text',
    id: 'address',
    rules: {
      max: 255,
    }
  },
];

const EDIT_CONTACT_POINT_FIELD = [
  {
    label: 'full_name',
    type: 'text',
    id: 'full_name',
    rules: {
      max: 255,
      required: true
    }
  },
  {
    label: 'phone',
    type: 'text',
    id: 'phone',
    rules: {
      max: 255,
    }
  },
  {
    label: 'Email',
    type: 'text',
    id: 'email',
    rules: {
      max: 255,
    }
  },
  {
    label: 'job_position',
    type: 'text',
    id: 'job_position',
    rules: {
      max: 255,
    }
  },
  {
    label: 'address',
    type: 'text',
    id: 'address',
    rules: {
      max: 255,
    }
  },
];

const IMPORT_CONTACT_FIELD = [
  {
    label: 'list_name',
    type: 'text',
    id: 'list_name',
    rules: {
      max: 255,
      required: true,
    }
  },
  {
    label: 'unit',
    type: 'select',
    id: 'unit',
    rules: {
      required: true,
    },
    options: []
  },
  {
    label: 'source',
    type: 'text',
    id: 'source',
    rules: {
      max: 255,
    }
  },
  {
    label: 'file',
    type: 'file',
    id: 'file',
    rules: {
      required: true,
      max: MAX_IMPORT_FILE_SIZE,
      mimes: ['text/csv', 'application/csv'],
      mimes_name: ['csv'],
    }
  },
  {
    label: '',
    placeholder: 'button_download',
    type: 'button-download',
    url: '/Contact 22_02_2023 - sample.csv',
  },
];

const IMPORT_FILE = [
  {
    label: 'file',
    type: 'file',
    id: 'file',
    rules: {
      required: true,
      max: MAX_IMPORT_FILE_SIZE,
      mimes: ['text/csv', 'application/csv'],
      mimes_name: ['csv'],
    }
  },
  {
    label: '',
    placeholder: 'button_download',
    type: 'button-download',
    url: '/Contact 22_02_2023 - sample.csv',
  },
];

const RESULT_FIELD = [
  { label: 'result', type: 'radio', options: ['To do', 'Sent', 'Unsent'], hideLabel: true, required: true },
  { label: 'note', type: 'textarea', hideLabel: true },
  { label: 'image', type: 'file', hideLabel: true },
];

const HTTP_STATUSES = {
  HTTP_UNPROCESSABLE_ENTITY: 422,
};

const IMPORT_BLACKLIST_OPTIONS = {
  import_all: {
    name: 'Import All',
    value: 'import_all',
  },
  skip_all: {
    name: 'Skip All',
    value: 'skip_all',
  },
  mix: {
    name: 'Mix',
    value: 'mix',
  },
};

const IMPORT_FORM_TYPE = {
  add_existing: 'add_existing',
  add_new: 'add_new',
  add_blacklist: 'add_blacklist',
}
const TEAM_COLUMN = [
  'Team ID',
  'team_name',
  'team_manager',
  'members',
  'unit',
  'update_date',
  'created_date',
];

const DEAL_COLUMN = [
  'name',
  'contact',
  'contact_point',
  'expected_revenue',
  'unit',
  'PIC',
  'stage',
  'update_date',
  'created_date',
];

const TEAM_FIELD_CREATE = [
  { label: 'Team Name', type: 'text', required: true, max: 255 },
  {
    label: 'Unit', type: 'select', required: true,
    options: []
  },
  { label: 'Team Manager', type: 'select', required: true, max: 255 },
  {
    label: 'Members', type: 'selectMulti', required: true,
    options: []
  },
];

const DEAL_FIELD = [
  { label: 'name', name: 'name', type: 'text', required: true, max: 255 },
  {
    label: 'contact', name: 'contact_ids', type: 'selectMulti', required: true,
    options: []
  },
  {
    label: 'contact_point', name: 'contact_point_ids', type: 'selectMulti',
    options: []
  },
  { label: 'owner', name: 'user_id', type: 'select', max: 255, options: [] },
  { label: 'team', name: 'team_id', type: 'select', max: 255, options: [] },
  { label: 'stage', name: 'stage_id', type: 'select', max: 255, options: [] },
  { label: 'expected_close_date', name: 'closing_date', type: 'date', max: 255 },
  { label: 'expected_revenue', name: 'revenue', type: 'number', max: 99999999999999999.99, min: 0.01 },
  { label: 'currency', name: 'currency_id', type: 'select', required: true, options: [] },
];

const DEAL_FIELD_EDIT_FAST = [
  { label: 'Name', name: 'name', type: 'text', required: true, max: 255 },
  { label: 'Owner', name: 'user_id', type: 'select', max: 255, options: [] },
  { label: 'Team', name: 'team_id', type: 'select', max: 255, options: [] },
  { label: 'Stage', name: 'stage_id', type: 'select', max: 255, options: [] },
  { label: 'Expected Closing Date', name: 'closing_date', type: 'date', max: 255 },
  { label: 'Expected Revenue', name: 'revenue', type: 'number', max: 99999999999999999.99, min: 0.01 },
];

const CONTACT_FORM_FIELD_EDIT = [
  { label: 'name', type: 'text', required: true, max: 255 },
  { label: 'Website', type: 'text', max: 255, },
  { label: 'Email', type: 'text', max: 255, },
  { label: 'contact_form_url', name: "contact_form_url", type: 'text', max: 1000, },
];

const DEAL_RELATIONS = defineConstantObject({
  contact: {
    name: 'Contact',
    relationName: 'contacts',
  },
  contactPoint: {
    name: 'Contact Point',
    relationName: 'contactPoints',
  },
});

const LIST_ITEM_FIELD = [
  { label: 'name', name: 'name', type: 'text', required: true, max: 255 },
  { label: 'quantity', name: 'quantity', type: 'text', required: true, max: 99999999999999999.99 },
  { label: 'unit_price', name: 'unit_price', type: 'text', required: true, max: 99999999999999999.99 },
  { label: 'total_price', name: 'total', type: 'text', required: false, max: 99999999999999999.99 },
];

const LIST_REVENUE_FIELD = [
  { label: 'month', name: 'month', type: 'text', required: true, max: 255 },
  { label: 'revenue', name: 'revenue', type: 'text', required: true, max: 99999999999999999.99 },
];

const LIST_INVOICE_FIELD = [
  { label: 'invoice_code', name: 'invoice_code', type: 'text', required: false, max: 255, readOnly: true },
  { label: 'invoice_amount_pre', name: 'invoice_amount', type: 'number', required: true, max: 99999999999999999.99, readOnly: false },
  { label: 'tax', name: 'tax', type: 'number', required: false, max: 10000, minEqual: 0, readOnly: false },
  { label: 'invoice_amount_after', name: 'invoice_amount_after_tax', type: 'number', required: false, readOnly: true },
  { label: 'received_amount', name: 'received_amount', type: 'number', required: false, max: 99999999999999999.99, readOnly: false },
  { label: 'status', name: 'status', type: 'select', options: [], required: true, max: 255, readOnly: false },
  { label: 'create_date', name: 'create_date', type: 'text', required: true, max: 255, readOnly: false },
  { label: 'due_date', name: 'due_date', type: 'text', max: 255, required: true, readOnly: false },
  { label: 'payment_date', name: 'payment_date', type: 'text', required: false, max: 255, readOnly: false },
  { label: 'note', name: 'note', type: 'text', required: false, max: 255, readOnly: false },
];

const TASK_COLUMN = [
  'result',
  'name',
  'contact',
  'type',
  // 'Object',
  'unit',
  'PIC',
  'deadline',
  'update_date',
  'created_date',
];

const RESULT_TASK_OPTION = [
  'Doing',
  'Customer with needs',
  'Customer without needs',
  'Need another meeting',
  'Having a meeting',
  "Not having a meeting",
  'The customer submitted their requirements',
  'The customer did not submit their requirements',
  'Created',
  'Succeeded',
  'Failed but has chance in future',
  'Failed and has no chance in future',
  'Failed',
  'Done',
];

const TYPE_TASK_OPTION = [
  'Meeting',
  'Phone/Mail',
  'Requesting customer to submit requirements',
  'Creating proposal',
  'Negotiating with cusomter',
  'Signing a contract with customer',
  'Customer Care',
];

const OBJECT_TASK_OPTION = [
  'Contact',
  'Deal',
];

const ACTION_LOG = [
  { action: 'updated_task', label: 'task_edit_history' },
  { action: 'updated_note', label: 'note_edit_history' },
  { action: 'updated_deal', label: 'deal_edit_history' },
  { action: 'contact_updated_information', label: 'contact_edit_history' },
];

const ACTION_DEAL_LOG = [
  { action: 'updated_deal', label: 'deal_edit_history' },
  { action: 'updated_note', label: 'note_edit_history' },
  { action: 'updated_order', label: 'order_edit_history' },
  { action: 'updated_items_from_deal', label: 'list_item_edit_history' },
];

const CREATE_ORDER_FIELD = [
  [{ label: 'name', name: 'name', type: 'text', required: true, max: 255 },
  { label: 'status', name: 'status', type: 'select', options: [], required: true, max: 255 },
  { label: 'quotation', name: 'quotation', type: 'text', required: false, max: 255 },
  {
    label: 'file_upload',
    type: 'file',
    name: 'file',
    required: false,
    max: 10,
    mimes: ['image/png', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf'],
    mimes_name: ['jpg', 'jpeg', 'png', 'doc', 'docx', 'xls', 'xlsx', 'pdf'],
  },
  { label: 'upload_later', name: 'upload_later', type: 'checkbox', required: false },
  { label: 'internal_project', name: 'internal_project', type: 'checkbox', required: false }
  ],
  [{ label: 'contact', name: 'contact', type: 'select', max: 255, options: [], required: true },
  { label: 'product', name: 'product', type: 'select', max: 255, options: [], required: true },
  { label: 'expected_start_date', name: 'start_date', type: 'date', max: 255, required: true },
  { label: 'exchange_rate_order', name: 'exchange_rate_order', type: 'text', max: 255, required: false, isDisabled: true },
  {
    label: 'estimate_file',
    type: 'file',
    name: 'estimate_file',
    required: false,
    max: 10,
    mimes: ['image/png', 'image/jpeg', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf'],
    mimes_name: ['jpg', 'jpeg', 'png', 'doc', 'docx', 'xls', 'xlsx', 'pdf'],
  }
  ],
  [{ label: 'deal', name: 'deal', type: 'select', max: 255, options: [], required: true },
  { label: 'order_date', name: 'order_date', type: 'date', max: 255, required: false, isDisabled: true },
  { label: 'expected_end_date', name: 'end_date', type: 'date', max: 255, required: true },
  { label: 'exchange_rate_payment', name: 'exchange_rate_payment', type: 'text', max: 255, required: false, isDisabled: true }],
];

const CREATE_ORDER_FIELD_VALIDATE = [
  { label: 'Name', name: 'name', type: 'text', required: true, max: 255 },
  { label: 'Status', name: 'status', type: 'select', options: [], required: true, max: 255 },
  { label: 'Quotation', name: 'quotation', type: 'text', required: false, max: 255 },
  {
    label: 'File Upload',
    type: 'file',
    name: 'file',
    required: false,
    max: 10,
    // mimes: ['text/csv', 'application/csv'],
    // mimes_name: ['csv'],
  },
  { label: 'Internal Project', name: 'internal_project', type: 'checkbox', required: false },
  { label: 'Contact', name: 'contact', type: 'select', max: 255, options: [], required: true },
  { label: 'Product', name: 'product', type: 'select', max: 255, options: [], required: true },
  { label: 'Expected Start Date', name: 'start_date', type: 'date', max: 255, required: true },
  { label: 'Deal', name: 'deal', type: 'select', max: 255, options: [], required: true },
  { label: 'Order Date', name: 'order_date', type: 'date', max: 255, required: false, isDisabled: true },
  { label: 'Expected End Date', name: 'end_date', type: 'date', max: 255, required: true },
  { label: 'Exchange rate - Order Date (VND)', name: 'exchange_rate_order', type: 'text', max: 255, required: false, isDisabled: true },
  { label: 'Exchange rate - Payment Successful (VND)', name: 'exchange_rate_payment', type: 'text', max: 255, required: false, isDisabled: true },
  {
    label: 'Estimate File',
    type: 'file',
    name: 'estimate_file',
    required: false,
    max: 10,
  },
  { label: 'Upload later', name: 'upload_later', type: 'checkbox', required: false },
];

const ACTION_TASKS_LOG = [
  'auto_created_task',
  'task_created',
  'updated_task_deadline',
  'updated_task_type',
  'task_assigned',
  'updated_task_result',
  'added_task_comment',
  'updated_task_name',
  'updated_task_description'
]

const ORDERS_COLUMN = [
  'name',
  'order_date',
  'total',
  'contact',
  'deal',
  'unit',
  'status',
  'PIC',
  'order_file',
  'estimate_file',
  'expected_start',
  'expected_end',
];

const INVOICE_COLUMN = [
  'invoice_code',
  'invoice_name',
  'order_name',
  'unit',
  'saler',
  'invoice_amount_pre',
  'tax',
  'invoice_amount_after',
  'received_amount',
  'currency',
  'status',
  'note',
  'payment_date',
  'due_date',
  'create_date',
  'last_updated'
];

const PROPOSAL_FIELD_CREATE = [
  [
    {
      label: 'Project type',
      name: 'project_type',
      hideLabel: false,
      type: 'radio',
      required: true,
      options: [{ label: "ODC", value: 1 }, { label: "Project Base", value: 2 }, { label: "Need advice", value: 3 }],
    },
    {
      label: 'Budget (Man Month)', name: 'budget', type: 'number', max: 99999999999999999.99, min: 0.1, hideLabel: true, required: false, hideError: true
    },
    {
      label: 'ODC-detail',
      numberMembers: {
        label: 'Number members', name: 'number_members', type: 'number', max: 99999999999999999.99, min: 0.5, hideLabel: false, required: true, hideError: true
      },
      numberDay: {
        label: 'Number day', name: 'number_day', type: 'number', max: 99999999999999999, min: 1, hideLabel: false, required: true, hideError: true
      },
      hideLabel: true
    },
    {
      label: 'Project type advice',
      name: 'project_type_advice',
      hideLabel: true,
      type: 'textarea',
      max: 500,
      required: true,
      hideError: true
    },
    { label: 'Related information', name: 'related_information', type: 'textarea', max: 5000, required: true, hideLabel: false, },
    {
      label: 'Desired project type', name: 'desired_project_type', type: 'checkbox', required: true,
      hideLabel: false,
      options: [{ label: "Estimation Proposal", value: 1 }, { label: "Technical Proposal", value: 2 },
      { label: "Schedule Proposal", value: 3 }]
    },
    {
      label: 'Application type', name: 'application_type', type: 'selectMulti', hideLabel: false,
      required: false,
      options: [],
    },
    {
      label: 'File Upload',
      type: 'file',
      name: 'file',
      required: false,
      max: 100,
      // mimes: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      //   'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf',
      //   "text/csv", "text/plain"
      // ],
      // mimes_name: ['doc', 'docx', 'xls', 'xlsx', 'pdf', 'csv'],
    },
  ],
  [
    {
      label: 'Technology',
      hideLabel: false,
      name: 'technology',
      type: 'radio',
      required: true,
      options: [{ label: "Choose technology", value: 1 }, { label: "Need advice", value: 2 }],
      hideError: true
    },
    {
      label: 'Technology select',
      name: 'technology_select',
      hideLabel: true,
      type: 'selectMulti',
      required: true,
      options: [],
      hideError: true
    },
    {
      label: 'Technology advice',
      name: 'technology_advice',
      hideLabel: true,
      type: 'textarea',
      max: 500,
      required: true,
      hideError: true
    },
    { label: 'Deadline', name: 'deadline', type: 'date', required: false, readOnly: false, hideLabel: false, max: 255 },
    {
      label: 'Sale Channel', name: 'sale_channel', type: 'selectMulti', hideLabel: false,
      required: false,
      options: []
    },
    {
      label: 'Project profession',
      name: 'project_profession',
      type: 'selectMulti',
      hideLabel: false,
      required: false,
      options: []
    },
    { label: 'Proposal Name', name: 'proposal_name', type: 'text', required: true, max: 255, readOnly: true },
  ]
];

const IMPORT_INPUT_ENCODINGS_ALLOWED = ['UTF-8', 'Shift-JIS'];

const CREATE_QUOTATION_FIELD = [
  [
    { label: 'name', name: 'name', type: 'text', required: true, max: 255 },
    { label: 'status', name: 'status', type: 'select', options: [], required: true, max: 255 },
    { label: 'forecast_start_date', name: 'start_date', type: 'date', max: 255, required: false },
  ],
  [
    { label: 'contact', name: 'contact', type: 'select', max: 255, options: [], required: true },
    { label: 'currency', name: 'currency', type: 'select', max: 255, options: [], required: true, disabled: true },
    { label: 'forecast_end_date', name: 'end_date', type: 'date', max: 255, required: false },
  ],
  [
    { label: 'deal', name: 'deal', type: 'select', max: 255, options: [], required: true, disabled: true },
    {
      label: 'estimation_cost', name: 'estimation_cost', type: 'number', min: 0.1, max: 99999999999999999.99, disabled: true,
      options: [{ label: "Need Proposal", value: 1 }, { label: "Labo", value: 2 }],
    },
  ]
]

const CREATE_PROPOSAL_FIELD = [
  [
    { label: 'sender', name: 'sender', type: 'text', required: true, disabled: true, hideLabel: false },
    { label: 'proposal_name', name: 'proposal_name', type: 'text', disabled: true, required: true, max: 255 },
    {
      label: 'contact_point', name: 'contact_point_ids', type: 'select', hideLabel: false,
      required: true,
      options: []
    },
    { label: 'related_information', name: 'related_information', type: 'textarea', max: 5000, required: true, hideLabel: false, },
    {
      label: 'project_type',
      name: 'project_type',
      hideLabel: false,
      type: 'radio',
      required: true,
      options: [{ label: "ODC", value: 1 }, { label: "Project Base", value: 2 }, { label: "Need advice", value: 3 }],
    },
    { label: 'budget', name: 'budget', type: 'number', max: 99999999999999999.99, min: 0.1, hideLabel: true, required: false, hideError: true },
    {
      label: 'odc_detail',
      name: "odc_detail",
      numberMembers: { label: 'Number members', name: "number_members", type: 'number', max: 99999999999999999.99, min: 0.5, hideLabel: false, required: true, hideError: true },
      numberDay: { label: 'Number day', name: "number_day", type: 'number', max: 99999999999999999, min: 1, hideLabel: false, required: true, hideError: true },
      hideLabel: true
    },
    {
      label: 'project_type_advice',
      name: 'project_type_advice',
      hideLabel: true,
      type: 'textarea',
      max: 500,
      required: true,
      hideError: true
    }
  ],
  [
    {
      label: 'desired_project_type', name: 'desired_project_type', type: 'checkbox', required: true,
      hideLabel: false,
      options: [{ label: "Estimation Proposal", value: 1 }, { label: "Technical Proposal", value: 2 },
      { label: "Schedule Proposal", value: 3 }]
    },

    {
      label: 'technology',
      name: "technology",
      hideLabel: false,
      type: 'radio',
      required: true,
      options: [{ label: "Choose technology", value: 1 }, { label: "Need advice", value: 2 }],
    },
    {
      label: 'technology_select',
      name: "technology_select",
      hideLabel: true,
      type: 'selectMulti',
      required: true,
      options: [],
      hideError: true
    },
    {
      label: 'technology_advice',
      name: 'technology_advice',
      hideLabel: true,
      type: 'textarea',
      max: 500,
      required: true,
      hideError: true
    },
    {
      label: 'file_upload',
      type: 'file',
      name: 'file',
      required: false,
      max: 10,
      // mimes: ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      //   'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/pdf',
      //   "text/csv", "text/plain"
      // ],
      // mimes_name: ['doc', 'docx', 'xls', 'xlsx', 'pdf', 'csv'],
    },
    {
      label: 'project_profession',
      name: 'project_profession',
      type: 'selectMulti',
      hideLabel: false,
      required: false,
      options: []
    },
    {
      label: 'sale_channel', name: 'sale_channel', type: 'selectMulti', hideLabel: false,
      required: false,
      options: []
    },
    { label: 'deadline', name: "deadline", type: 'date', max: 255, required: false, hideLabel: false, },
    {
      label: 'application_type', name: 'application_type', type: 'selectMulti',
      hideLabel: false,
      required: false,
      options: []
    }
  ],
]

const EDIT_PROPOSAL_FIELD = [
  [{ label: 'sender', name: 'sender', type: 'text', required: true, disabled: true, hideLabel: false }],
  [{
    label: 'contact_point', name: 'contact_point_ids', type: 'select', hideLabel: false,
    required: true,
    options: [],
    disabled: true
  }]
]

const HEADER_TABLE_PROPOSAL_QUOTATION = [
  'proposal_name',
  'cost',
  'receive_date',
  'estimator',
  'project_type',
  'link_proposal',
  'estimate_file',
  'cancel_reason'
]

const HEADER_TABLE_PROPOSAL_QUOTATION_LABO = [
  'proposal_name',
  'cost',
  'receive_date',
  'project_type',
  'estimator',
  'cost_adjustment_reasons',
  'cancel_reason'
]

const DISABLED_STATUS_QUOTATION = [
  { value: 1, label: 'Estimating' },
  { value: 2, label: 'Negotiation' },
  { value: 3, label: 'Successful negotiation' }
];

const DETAIL_PROPOSAL_FIELD = [
  [
    { label: 'Proposal Name', name: 'proposal_name', type: 'text', readOnly: true, required: true, hideLabel: false, max: 255 },
    { label: 'Cost', name: 'cost', type: 'text', readOnly: true, hideLabel: false, max: 255 },
    { label: 'Receive Date', name: 'receive_date', type: 'date', readOnly: true, hideLabel: false, max: 255 },
    { label: 'Related information', name: 'related_information', type: 'textarea', max: 5000, required: true, hideLabel: false, readOnly: true },
    {
      label: 'Desired project type', name: 'desired_project_type', type: 'checkbox', required: true, readOnly: true,
      hideLabel: false,
      options: [{ label: "Estimation Proposal", value: 1 }, { label: "Technical Proposal", value: 2 },
      { label: "Schedule Proposal", value: 3 }]
    },
    { label: 'Note', name: 'note', type: 'textarea', max: 500, required: false, hideLabel: false, readOnly: true },
    {
      label: 'Sale Channel', name: 'sale_channel', type: 'selectMulti', hideLabel: false,
      required: false,
      options: [],
      readOnly: true,
    },
  ],
  [
    {
      label: 'Project profession',
      name: 'project_profession',
      type: 'selectMulti',
      hideLabel: false,
      required: false,
      readOnly: true,
      options: []
    },
    {
      label: 'Project type',
      name: 'project_type',
      hideLabel: false,
      type: 'radio',
      required: true,
      options: [{ label: "ODC", value: 1 }, { label: "Project Base", value: 2 }, { label: "Need advice", value: 3 }],
      readOnly: true,
    },
    {
      label: 'Budget (Man Month)', name: 'budget', type: 'text', max: 99999999999999999.99, min: 0.1, hideLabel: true, required: false,
      readOnly: true
    },
    {
      label: 'ODC-detail',
      numberMembers: {
        label: 'Number members', name: "number_members", type: 'number', max: 99999999999999999.99, min: 0.5, hideLabel: false, required: true,
        readOnly: true
      },
      numberDay: {
        label: 'Number day', name: "number_day", type: 'number', max: 99999999999999999, min: 1, hideLabel: false, required: true,
        readOnly: true
      },
      hideLabel: true
    },
    {
      label: 'Project type advice',
      name: 'project_type_advice',
      hideLabel: true,
      type: 'textarea',
      max: 500,
      required: true,
      hideError: true
    },
    {
      label: 'Application type', name: 'application_type', type: 'selectMulti', hideLabel: false,
      required: false,
      options: [],
      readOnly: true,
    },
    { label: 'Estimator', name: 'estimator', type: 'text', readOnly: true, required: true, hideLabel: false, max: 255 },
    { label: 'Link proposal', name: 'link_proposal', type: 'text', readOnly: true, required: true, hideLabel: false, max: 255 },
    {
      label: 'File Upload',
      type: 'file',
      name: 'file',
      required: false,
      max: 100,
    },
    {
      label: 'Technology',
      hideLabel: false,
      name: 'technology',
      type: 'radio',
      required: true,
      options: [{ label: "Choose technology", value: 1 }, { label: "Need advice", value: 2 }],
      readOnly: true,
    },
    {
      label: 'Technology select',
      name: 'technology_select',
      hideLabel: true,
      type: 'selectMulti',
      required: true,
      options: [],
      readOnly: true
    },
    {
      label: 'Technology advice',
      name: 'technology_advice',
      hideLabel: true,
      type: 'textarea',
      max: 500,
      required: true,
      readOnly: true
    },
    { label: 'Deadline', name: 'deadline', type: 'date', required: false, readOnly: true, hideLabel: false, max: 255 },
  ],
];

const CREATE_INVOICE_FIELD = [
  { label: 'Invoice Code', name: 'invoice_code', type: 'text', disabled: true },
  { label: 'Invoice Name', name: 'name', type: 'text', required: false, max: 255 },
  { label: 'Invoice Amount', name: 'invoice_amount', type: 'text', required: true, max: 99999999999999999.99 },
  { label: 'Payment due date', name: 'due_date', type: 'date', max: 255, required: true },
  { label: 'Payment Status', name: 'status_id', type: 'select', options: [], required: true },
  { label: 'Order', name: 'order', type: 'text', disabled: true },
  { label: 'Unit', name: 'unit', type: 'text', disabled: true },
  { label: 'Saler', name: 'saler', type: 'text', disabled: true },
  { label: 'Currency', name: 'currency', type: 'text', disabled: true },
];

const CREATE_INVOICE_FIELD_VALIDATE = [
  { label: 'Invoice Name', name: 'name', type: 'text', required: false, max: 255 },
  { label: 'Invoice Amount', name: 'invoice_amount', type: 'text', required: true, max: 99999999999999999.99 },
  { label: 'Payment due date', name: 'due_date', type: 'date', max: 255, required: true },
  { label: 'Payment Status', name: 'status_id', type: 'select', options: [], required: true },
];

const LIST_INVOICE_COLOR_STATUS = [
  { id: 1, name: "New", color: "black" },
  { id: 2, name: "Sent", color: "#0063B0" },
  { id: 3, name: "Paid", color: "#29C203" },
  { id: 4, name: "Partially Paid", color: "#EE8062" },
  { id: 5, name: "Overdue", color: "#FF0000" },
];

const HEADER_TABLE_DASHBOARD_TASK = [
  "name",
  "type",
  "result",
  "contact",
  "deadline"
];

const HEADER_TABLE_DASHBOARD_QUOTATION = [
  "quotation",
  "status",
  "estimate_cost",
  "expect_start_date"
];

const MONTH_OF_TARGET = {
  'ja': [
    "1月",
    "2月",
    "3月",
    "4月",
    "5月",
    "6月",
    "7月",
    "8月",
    "9月",
    "10月",
    "11月",
    "12月"
  ],
  "en": [
    "January (1)",
    "February (2)",
    "March (3)",
    "April (4)",
    "May (5)",
    "June (6)",
    "July (7)",
    "August (8)",
    "September (9)",
    "October (10)",
    "November (11)",
    "December (12)"
  ]
};

const SALE_TYPE_TARGET = [
  {
    name: "revenue_distribution",
    type: 1
  },
  {
    name: "order_revenue",
    type: 2
  }
];

const LIST_STAGE_COLOR = [
  { id: 0, name: "iql", color: "#465e8a" },
  { id: 1, name: "mql", color: "#8942b8" },
  { id: 2, name: "sql", color: "#e73123" },
  { id: 3, name: "potential_customer", color: "#77c4ce" },
  { id: 4, name: "customer", color: "#5fbe39" },
  { id: 5, name: "suspend", color: "black" },
];

const FIELD_ORDER_REPORT = {
  target: "target_revenue",
  actual_renevue: "actual_revenue",
  percent_target: "actual_target",
  contact: "contact",
  revenue_per_contact: "revenue_per_contact",
  expected: "expected",
};

const COUNTRY_UNIT_JMU = ['Japan'];
const COUNTRY_UNIT_SFS = ['Vietnam'];
const COUNTRY_UNIT_EMU = ['Indonesia', 'Malaysia', 'Singapore', 'Philippines', 'Thailand'];
const CHIP_DATA_ORDER = [
  { id: 'all', label: "all" },
  { id: 'revenue_distribution_needed', label: "revenue_distribution_needed" },
  { id: 'invoice_creation_needed', label: "invoice_creation_needed" },
  { id: 'order_file_needed', label: "order_file_needed" },
  { id: 'estimate_file_needed', label: "estimate_file_needed" },
];

const UNIT_USER = {
  'SFS': 1,
  'JMU': 2,
  'EMU': 3,
  'ETS': 4,
  'HUO': 5,
};

const PAYMENT_STATUS = [
  { id: 1, name: 'Completed' },
  { id: 2, name: 'Partially Paid' },
  { id: 3, name: 'Unpaid' }
];

const LANGUAGES = [
  { label: "english", code: "en" },
  { label: "vietnamese", code: "vi" },
  { label: "japanese", code: "ja" },
];

const ORDER_HISTORY_LOG_COLUMN = [
  'time',
  'unit_log',
  'actor',
  'action_log',
  'order_log',
  'previous_or',
  'updated_or',
  'previous_dr',
  'updated_dr',
  'note_log',
  'order_detail_log'
];
const INVOICE_HISTORY_LOG_COLUMN = [
  'time',
  'unit_invoice_log',
  'actor',
  'action_log',
  'invoice_code_log',
  'order_invoice_log',
  'inv_amount_before',
  'inv_amount_after',
  'receive_amount_before',
  'receive_amount_after',
  'invoice_detail_log'
];
const ACTION_TYPE_LOG = [
  { value: 'created', label: 'Create' },
  { value: 'edit', label: 'Edit' },
  { value: 'deleted', label: 'Delete' }
];
export {
  STATES,
  CONTACT_COLUMN,
  CONTACT_FIELD_CREATE,
  ACTION,
  STAGE_OPTION,
  STAGE_OBJECT,
  UNIT_OBJECT,
  TYPE_CONTACT,
  TYPE_CONTACT_OBJECT,
  FILLTER_CATEGORY,
  LIST_FIELD_CREATE,
  LIST_COLUMN,
  IMPORT_CONTACT_FIELD,
  GET_CONTACT_FIELD,
  CONTACT_FORM_COLUMN,
  RESULT_FIELD,
  REPORT_COLUMN,
  LIST_DETAIL_COLUMN,
  MAX_IMPORT_FILE_SIZE,
  ROLES,
  HTTP_STATUSES,
  IMPORT_BLACKLIST_OPTIONS,
  RESULT_OPTION,
  ADD_CONTACT_POINT_FIELD,
  EDIT_CONTACT_POINT_FIELD,
  ACTION_BLACK_LIST,
  IMPORT_FILE,
  IMPORT_FORM_TYPE,
  TEAM_COLUMN,
  DEAL_COLUMN,
  TEAM_FIELD_CREATE,
  ACTION_TEAM,
  CONTACT_FORM_FIELD_EDIT,
  DEAL_FIELD,
  DEAL_RELATIONS,
  DEAL_FIELD_EDIT_FAST,
  LIST_ITEM_FIELD,
  CONTACT_TO_LIST_FIELD_CREATE,
  TASK_COLUMN,
  RESULT_TASK_OPTION,
  TYPE_TASK_OPTION,
  OBJECT_TASK_OPTION,
  ACTION_LOG,
  CREATE_ORDER_FIELD,
  LIST_REVENUE_FIELD,
  ORDERS_COLUMN,
  ACTION_ORDERS,
  ACTION_TASKS_LOG,
  CREATE_ORDER_FIELD_VALIDATE,
  PROPOSAL_FIELD_CREATE,
  ACTION_DEAL_LOG,
  IMPORT_INPUT_ENCODINGS_ALLOWED,
  ACTION_LIST,
  ACTION_LIST_DETAIL,
  ACTION_CONTACT_FORM,
  CREATE_QUOTATION_FIELD,
  CREATE_PROPOSAL_FIELD,
  EDIT_PROPOSAL_FIELD,
  HEADER_TABLE_PROPOSAL_QUOTATION,
  DISABLED_STATUS_QUOTATION,
  DETAIL_PROPOSAL_FIELD,
  LIST_INVOICE_FIELD,
  HEADER_TABLE_PROPOSAL_QUOTATION_LABO,
  ACTION_INVOICES,
  INVOICE_COLUMN,
  CREATE_INVOICE_FIELD,
  CREATE_INVOICE_FIELD_VALIDATE,
  LIST_INVOICE_COLOR_STATUS,
  HEADER_TABLE_DASHBOARD_TASK,
  HEADER_TABLE_DASHBOARD_QUOTATION,
  MONTH_OF_TARGET,
  SALE_TYPE_TARGET,
  LIST_STAGE_COLOR,
  FIELD_ORDER_REPORT,
  COUNTRY_UNIT_JMU,
  COUNTRY_UNIT_SFS,
  COUNTRY_UNIT_EMU,
  CHIP_DATA_ORDER,
  UNIT_USER,
  PAYMENT_STATUS,
  LANGUAGES,
  ORDER_HISTORY_LOG_COLUMN,
  INVOICE_HISTORY_LOG_COLUMN,
  ACTION_TYPE_LOG,
};

