const vi = {
  translation: {
    homepage: 'Trang chủ',
    about: 'Giới thiệu',
    login: 'Đăng nhập',
    logout: 'Đăng xuất',
    profile: 'Thông tin cá nhân',
    english: 'Tiếng Anh',
    vietnamese: 'Tiếng Việt',
    japanese: 'Tiếng Nhật',
    dashboard: 'Bảng điều khiển',
    contact_menu: 'Liên hệ',
    contact_form: "Mẫu liên hệ",
    sale: 'Doanh số',
    finance: 'Tài chính',
    report: 'Báo cáo',
    setting: 'Cài đặt',
    list: 'Danh sách',
    deal: 'Giao dịch',
    task: 'Công việc',
    order: 'Đơn hàng',
    invoice: 'Hóa đơn',
    revenue: 'Doanh thu',
    team: 'Nhóm',
    target: 'Mục tiêu',
    dashboard_content: {
      personal_info: 'Thông tin cá nhân',
      my_task: 'Công việc của tôi',
      project_under_negotiation: 'Dự án đang đàm phán',
      total: 'Tổng'
    },
    name: 'Tên',
    type: "Loại",
    result: "Kết quả",
    contact: "Liên hệ",
    deadline: "Thời hạn",
    quotation: "Báo giá",
    status: "Trạng thái",
    estimate_cost: "Dự toán chi phí",
    expect_start_date: "Ngày bắt đầu dự kiến",
    create: 'Tạo',
    import: 'Import',
    add_list: 'Thêm vào danh sách',
    all_contacts: 'Tất cả các liên hệ',
    blacklist: 'Danh sách đen',
    my_contact: 'Liên hệ của tôi',
    unassigned_contacts: 'Liên hệ chưa được chỉ định',
    phone: 'Số điện thoại',
    contact_form_url: 'URL mẫu liên hệ',
    unit: 'Đơn vị',
    stage: 'Giai đoạn',
    created_date: 'Ngày tạo',
    update_date: 'Ngày cập nhật',
    last_updated: 'Ngày cập nhật',
    showing: 'Hiển thị',
    to: 'đến',
    of: 'trong tổng số',
    entry: 'mục',
    entries: 'mục',
    message: {
      contact_create_success: 'Liên hệ đã được tạo thành công',
      contact_edit_success: 'Liên hệ đã được chỉnh sửa thành công',
      contact_import_success: 'Liên hệ đã được nhập thành công',
      error_import_contact: 'Lỗi khi nhập liên hệ',
      remove_contact_success: 'Đã gỡ bỏ liên hệ thành công',
      move_blacklist_success: 'Đã chuyển vào danh sách đen thành công',
      remove_blacklist_success: 'Đã gỡ khỏi danh sách đen thành công',
      stage_update_success: 'Giai đoạn liên hệ đã được cập nhật thành công',
      error_update_stage: 'Lỗi khi cập nhật giai đoạn liên hệ',
      filter_remove_success: 'Đã xóa bộ lọc thành công',
      error_remove_filter: 'Lỗi khi xóa bộ lọc',
      filter_create_success: 'Bộ lọc đã được tạo thành công',
      error_create_filter: 'Lỗi khi tạo bộ lọc',
      filter_update_success: 'Bộ lọc đã được cập nhật thành công',
      error_update_filter: 'Lỗi khi cập nhật bộ lọc',
      add_contact_list_success: 'Đã thêm liên hệ vào danh sách thành công',
      update_pic_success: 'Cập nhật PIC thành công',
      export_contact_success: 'Xuất liên hệ thành công.',
      export_contact_failed: 'Xuất liên hệ thẩt bại.'
    },
    action: 'Actions',
    stage_filter: 'Giai đoạn',
    unit_filter: 'Đơn vị',
    country: 'Quốc gia',
    city: 'Thành phố',
    my_filter: 'Bộ lọc của tôi',
    search: 'Tìm kiếm...',
    edit: 'Chỉnh sửa',
    export: 'Xuất dữ liệu',
    remove_blacklist: 'Gỡ khỏi danh sách đen',
    update_PIC: 'Cập nhật PIC',
    delete: 'Xoá',
    move_blacklist: 'Chuyển vào danh sách đen',
    export_contact: 'Xuất dữ liệu liên hệ',
    close: 'Đóng',
    contact_selected: 'Liên hệ đã được chọn',
    contacts_selected: 'Liên hệ đã được chọn',
    select_all: "Chọn tất cả các liên hệ trên tất cả các trang với tổng số {{ contactCount }} liên hệ.",
    confirm_delete_contact: 'Xác nhận xóa liên hệ',
    confirm_move_blacklist: 'Xác nhận chuyển vào danh sách đen',
    confirm_remove_blacklist: 'Xác nhận gỡ khỏi danh sách đen',
    title_confirm_delete: 'Bạn có chắc chắn muốn gỡ bỏ',
    this_contact: 'liên hệ này',
    these_contacts: 'các liên hệ này',
    from_blacklist: 'khỏi danh sách đen',
    from_old_list: 'từ danh sách cũ và chuyển vào danh sách đen',
    add_contact_list: 'Thêm liên hệ vào danh sách',
    add_existing: 'Đã có',
    create_new: 'Tạo mới',
    list_name: 'Tên danh sách',
    source: 'Nguồn',
    add: 'Thêm',
    cancel: 'Hủy',
    select_all_contact: 'Chọn tất cả các liên hệ trên tất cả các trang',
    message_error_list_name: 'Tên danh sách là bắt buộc',
    create_contact: 'Tạo liên hệ',
    address: 'Địa chỉ',
    industry: 'Ngành nghề',
    channel: 'Kênh',
    marketer: 'Nhân viên tiếp thị',
    saler: 'Nhân viên bán hàng',
    company_classification: 'Phân loại công ty',
    company: 'Công ty',
    individual: 'Cá nhân',
    update: 'Cập nhật',
    edit_contact: 'Sửa liên hệ',
    file: 'File',
    button_download: 'Tải xuống file mẫu',
    add_blacklist: 'Thêm vào danh sách đen',
    size: 'Kích thước',
    order_date: 'Ngày đặt hàng',
    total: 'Tổng số',
    order_file: 'File đơn hàng',
    estimate_file: 'File dự toán',
    expected_start: 'Ngày bắt đầu dự kiến',
    expected_end: 'Ngày kết thúc dự kiến',
    get_contact: 'Lấy liên hệ',
    assign_me: 'Được gán cho tôi',
    save: 'Save',
    input_number: 'Nhập số liệu',
    contact_point: 'Người liên hệ',
    expected_revenue: 'Doanh thu dự kiến',
    all_deal: 'Tất cả các giao dịch',
    my_deal: 'Giao dịch của tôi',
    total_expected_revenue: 'Tổng doanh thu dự kiến',
    all_task: 'Tất cả các công việc',
    my_task: 'Công việc của tôi',
    all: 'Tất cả',
    revenue_distribution_needed: 'Cần phân phối doanh thu',
    invoice_creation_needed: 'Cần tạo hóa đơn',
    order_file_needed: 'Cần file đơn hàng',
    estimate_file_needed: 'Cần file dự toán',
    invoice_name: 'Tên hóa đơn',
    order_name: 'Tên đơn hàng',
    invoice_amount_pre: 'Số tiền hóa đơn (Trước thuế)',
    tax: 'Thuế',
    invoice_amount_after: 'Số tiền hóa đơn (Sau thuế)',
    received_amount: 'Số tiền đã nhận',
    currency: 'Tiền tệ',
    note: 'Ghi chú',
    payment_date: 'Ngày thanh toán',
    due_date: 'Ngày đến hạn',
    create_date: 'Ngày tạo',
    total_received: 'Tổng số tiền đã nhận',
    current_month: 'Tháng hiện tại',
    overdue: 'Quá hạn',
    all_invoice: 'Tất cả hóa đơn',
    payment_status: 'Trạng thái thanh toán',
    order_revenue: 'Doanh thu đơn hàng',
    revenue_distribution: 'Phân phối doanh thu',
    invoice_revenue: 'Doanh thu từ hóa đơn',
    last_month: '3 tháng trước',
    next_month: '3 tháng tới',
    target_revenue: "Mục tiêu",
    actual_revenue: "Doanh thu thực tế",
    actual_target: "Thực tế/Mục tiêu (%)",
    revenue_per_contact: "Doanh thu trên mỗi liên hệ",
    expected: "Dự kiến",
    distribution: 'Phân phối',
    total_revenue_jmu: 'Tổng số(JPY)',
    total_revenue_emu: 'Tổng số(USD)',
    total_revenue: 'Tổng số(VND)',
    export_revenue: 'Xuất doanh thu',
    amount_pre_tax: 'Số tiền (Trước thuế)',
    amount_after_tax: 'Số tiền (Sau thuế)',
    receive_amount: 'Số tiền nhận được',
    title_report: 'Vui lòng chọn Danh sách và Ngày để xem Báo cáo',
    team_name: 'Tên nhóm',
    team_manager: 'Quản lý nhóm',
    members: 'Thành viên',
    company_unit: 'Công ty/Đơn vị',
    target_by_unit: "Mục tiêu của {{ unit }}",
    total_first_quater: 'Tổng cộng Quý 1',
    total_second_quater: 'Tổng cộng Quý 2',
    total_third_quater: 'Tổng cộng Quý 3',
    total_fourth_quater: 'Tổng cộng Quý 4',
    import_modal: 'Import Modal',
    system_field: 'Trường hệ thống',
    deal_create_success: "Giao dịch đã được tạo thành công",
    deal_create_fail: "Tạo giao dịch không thành công",
    deal_update_success: "Giao dịch đã được cập nhật thành công",
    deal_delete_success: "Giao dịch đã được xóa thành công",
    expected_close_date: 'Ngày kết thúc dự kiến',
    edit_deal: 'Chỉnh sửa giao dịch',
    create_deal: 'Tạo mới giao dịch',
    owner: 'Người quản lý',
    confirm_delete: 'Xác nhận xóa',
    edit_contact_point: 'Chỉnh sửa người liên hệ',
    add_contact_point: 'Thêm người liên hệ',
    full_name: "Họ và tên",
    job_position: "Chức vụ",
    private: 'Cá nhân',
    share_with: 'Chia sẻ với',
    people: 'Người',
    add_people: 'Thêm người',
    history_log: 'Nhật ký hoạt động',
    meeting: 'Cuộc họp',
    back_list: 'Trở về danh sách',
    this_deal: "giao dịch này",
    these_deal: "các giao dịch này",
    notification: 'Thông báo',
    deal_owner: 'Người quản lý giao dịch',
    add_contact: 'Thêm liên hệ',
    confirm_detach: 'Xác nhận gỡ bỏ',
    title_confirm_detach: 'Bạn có chắc chắn muốn gỡ bỏ',
    forecast_start_date: "Ngày bắt đầu dự báo",
    forecast_end_date: "Ngày kết thúc dự báo",
    total_price: "Tổng giá",
    expected_start_date: "Ngày bắt đầu dự kiến",
    expected_end_date: "Ngày kết thúc dự kiến",
    create_quotation: 'Tạo báo giá',
    quotation_info: "Thông tin báo giá",
    sender: "Người gửi",
    proposal_name: "Tên đề xuất",
    related_information: "Thông tin liên quan",
    project_type: "Loại dự án",
    budget: "Ngân sách (Man Month)",
    odc_detail: "Chi tiết ODC",
    project_type_advice: "Tư vấn loại dự án",
    desired_project_type: "Loại dự án mong muốn",
    technology: "Công nghệ",
    technology_select: "Chọn công nghệ",
    technology_advice: "Tư vấn công nghệ",
    file_upload: "File tải lên",
    project_profession: "Chuyên môn dự án",
    sale_channel: "Kênh bán hàng",
    application_type: "Loại ứng dụng",
    estimation_cost: 'Dự toán chi phí',
    cost: "Chi phí",
    receive_date: "Ngày nhận",
    estimator: "Người ước tính",
    link_proposal: "Liên kết đề xuất",
    cancel_reason: "Lý do hủy bỏ",
    cost_adjustment_reasons: "Lý do điều chỉnh chi phí",
    create_order: 'Tạo đơn hàng',
    upload_later: "Tải lên sau",
    internal_project: "Dự án nội bộ",
    product: "Sản phẩm",
    exchange_rate_order: "Tỷ giá hối đoái - Ngày đặt hàng (VND)",
    exchange_rate_payment: "Tỷ giá hối đoái - Thanh toán thành công (VND)",
    item_list: "Danh sách mục",
    add_item: "Thêm mục",
    quantity: "Số lượng",
    unit_price: "Đơn giá",
    title_add_item: 'Vui lòng thêm các mục',
    month: 'Tháng',
    title_revenue_distribution: 'Vui lòng chọn ngày dự kiến.',
    invoice_list: "Danh sách hóa đơn",
    title_invoice_list: "Vui lòng thêm hóa đơn",
    add_invoice: "Thêm hóa đơn",
    regenerate_invoice: "Tạo lại hóa đơn",
    confirm_regenerate_invoice: "Xác nhận tạo lại hóa đơn",
    content_confirm_invoice_1: "Việc tạo lại hóa đơn sẽ xóa tất cả các hóa đơn hiện tại.",
    content_confirm_invoice_2: "Bạn có chắc chắn muốn tạo lại hóa đơn không?",
    help: 'Hỗ trợ',
    order_title_revenue: '(Bỏ chọn để ẩn các đơn hàng trong tháng)',
    deal_note: 'Ghi chú về giao dịch',
    invoice_code: 'Invoice Code',
    search_activity: 'Tìm kiếm',
    show_detail: 'Xem chi tiết',
    updated_deal: "${user} đã cập nhật giao dịch ${deal}",
    updated_am: "${user} đã cập nhật AM từ ${from} thành ${to}.",
    updated_saler: "${user} đã cập nhật Saler từ ${from} thành ${to}.",
    updated_marketer: "${user} đã cập nhật Marketer từ ${from} thành ${to}.",
    contact_updated_information: "${user} đã cập nhật thông tin liên hệ.",
    added_task: "${user} đã thêm công việc ${task}.",
    updated_note: "${user} đã cập nhật ghi chú ${note}.",
    added_note: "${user} đã thêm ghi chú ${note}.",
    updated_task: "${user} đã cập nhật công việc ${task}.",
    contact_updated_stage: "${user} đã cập nhật giai đoạn từ ${from} thành ${to} trong danh sách ${list}.",
    added_contact_to_list: "${user} đã thêm liên hệ này vào danh sách ${list}",
    contact_created: "${user} đã tạo liên hệ này.",
    contact_point_created: "${user} đã tạo người liên hệ ${contact_point}.",
    added_deal: "${user} đã thêm giao dịch ${deal}.",
    added_existing_contact_points: "${user} đã thêm người liên hệ hiện có ${contact_point}.",
    deleted_note: "${user} đã xóa ghi chú ${note}.",
    detached_deal: "${user} đã tách khỏi giao dịch ${deal}.",
    contact_deleted: "${user} đã xóa liên hệ này.",
    deleted_deal: "${user} đã xóa giao dịch này.",
    deleted_task: "${user} đã xóa công việc ${task}.",
    moved_contact_to_blacklist: "${user} đã chuyển liên hệ này vào danh sách đen.",
    removed_contact_from_list: "${user} đã xóa liên hệ này khỏi danh sách ${list}.",
    removed_contact_from_blacklist: "${user} đã xóa liên hệ này khỏi danh sách đen.",
    task_created: "${user} đã tạo công việc này.",
    updated_task_type: "${user} đã thay đổi ${field} từ ${from} thành ${to}.",
    updated_task_description: "${user} đã cập nhật mô tả công việc.",
    added_task_comment: "${user} đã thêm một bình luận.",
    auto_created_task: "Hệ thống đã tạo công việc này.",
    updated_task_result: "${user} đã thay đổi ${field} từ ${from} thành ${to}.",
    updated_task_name: "${user} đã ${action} nhiệm vụ này từ ${from} thành ${to}.",
    detached_contact_points: "${user} đã tách người liên hệ ${contact_point}.",
    deal_created: "${user} đã tạo giao dịch này.",
    added_contact: "${user} đã thêm liên hệ ${contact}.",
    added_existing_contact_point_to_deal: "${user} đã thêm người liên hệ hiện có ${contact_point} vào giao dịch.",
    detached_contact_point_from_deal: "${user} đã tách người liên hệ ${contact_point} khỏi giao dịch.",
    deal_updated_stage: "${user} đã cập nhật giai đoạn từ ${from} thành ${to}.",
    added_items_to_deal: "${user} đã thêm các mục vào giao dịch.",
    updated_items_from_deal: "${user} đã cập nhật các mục trong giao dịch.",
    detached_contact: "${user} đã tách khỏi liên hệ ${contact}.",
    added_order: "${user} đã tạo đơn hàng ${order}.",
    updated_order: "${user} đã cập nhật đơn hàng ${order}.",
    task_assigned: "${user} đã giao nhiệm vụ này cho ${assigner}.",
    deleted_order: "${user} đã xóa đơn hàng ${order}.",
    updated_task_deadline: "${user} đã thay đổi ${field} từ ${from} thành ${to}.",
    task_edit_history: "Lịch sử chỉnh sửa nhiệm vụ",
    note_edit_history: "Lịch sử chỉnh sửa ghi chú",
    deal_edit_history: "Lịch sử chỉnh sửa giao dịch",
    contact_edit_history: "Lịch sử chỉnh sửa liên hệ",
    order_edit_history: "Lịch sử chỉnh sửa đơn hàng",
    list_item_edit_history: "Lịch sử chỉnh sửa các mục trong danh sách",
    added_a: "đã thêm một",
    meeting_title: "Bạn phải thêm ghi chú cuộc họp để hoàn thành nhiệm vụ này.",
    assigned_to: "Được giao cho",
    meeting_note: "ghi chú cuộc họp",
    quotation_title_1: "Vui lòng chờ cho đến khi",
    quotation_title_2: "được ước tính hoàn thành nhiệm vụ",
    add_comment: "Thêm bình luận",
    no_data: "Không tìm thấy dữ liệu",
    title_no_contact_point: "Liên hệ này không có người liên hệ nào",
    add_new_point: "Thêm người liên hệ mới",
    edit_contact_form: "Chỉnh sửa biểu mẫu liên hệ",
    exclude: "Loại trừ",
    list_exclude: "Danh sách",
    time: 'Thời gian',
    actor: 'Actor',
    previous_or: 'Trước OR',
    updated_or: 'Được sửa đổi OR',
    previous_dr: 'Trước DR',
    updated_dr: 'Được sửa đổi DR',
    order_detail_log: 'Chi tiết Order',
    invoice_detail_log: 'Chi tiết hoá đơn',
    unit_log: 'Bộ phận',
    action_log: 'Hành động',
    order_log: 'Order',
    order_invoice_log: 'Order',
    inv_amount_before: 'Inv Amount before',
    inv_amount_after: 'Inv Amount after',
    receive_amount_before: 'Receive Amount before',
    receive_amount_after: 'Receive Amount after',
    search_log: 'Tìm kiếm',
    invoice_code_log: 'Invoice Code',
    note_log: 'Ghi chú',
    unit_invoice_log: 'Bộ phận'
  },
};

export default vi;
