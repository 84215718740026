import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import TableCellInvoice from "./TableCellInVoice";
import { formatNumber } from "../../../utils/helper";
import { useEffect, useRef, useState } from "react";
import { fetchUnits } from "../../../store/unitSlice";
import { FIELD_ORDER_REPORT } from "../../../utils/constant";
import { incrementPage } from "../../../store/reportOrderSlice";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as IconPlane } from "./../../../assets/images/plane.svg";
import { useTranslation } from "react-i18next";
import { Checkbox } from "@mui/material";
import { fetchUncheckMonthUser, updateUncheckMonthByUser } from "../../../store/userSlice";

export default function ReportOrderItem({
  checkedCount,
  showItemOrder,
  showItemDistribution,
  showItemInvoice,
  loading,
  setLoading,
  loadMore,
  setLoadMore,
  unit,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showItemByMonth, setShowItemByMonth] = useState([]);
  const revenueDistribution = useSelector(
    (state) => state.reportOrder.revenueDistribution
  );
  const orderRevenue = useSelector((state) => state.reportOrder.orderRevenue);
  const invoiceRevenue = useSelector(
    (state) => state.reportOrder.invoiceRevenue
  );

  const reportOrder = useSelector((state) => state.reportOrder.reportOrder);
  const columnMonth = useSelector((state) => state.reportOrder.columnMonth);
  const loadMoreRef = useRef();
  const page = useSelector((state) => state.reportOrder.page);
  const lastPage = useSelector((state) => state.reportOrder.lastPage);
  const [unCheckAll, setUnCheckAll] = useState(false);
  const uncheckMonth = useSelector((state) => state.users.UncheckMonthUser);

  useEffect(() => {
    dispatch(fetchUnits());
    dispatch(fetchUncheckMonthUser());
    let array = [...showItemByMonth];
    setShowItemByMonth([...array]);
  }, [dispatch]);

  const renderRenevueTableCell = (data, dataFirstCheck, dataSecondCheck, month, index) => {
    const revenue = data?.revenue;
    const firstCheckRevenue = dataFirstCheck?.revenue;
    const secondCheckInvoice = dataSecondCheck?.invoice_amount;

    // Determine the value based on the conditions
    const value =
      (revenue !== 0 && revenue !== undefined) ||
        (firstCheckRevenue !== 0 && firstCheckRevenue !== undefined) ||
        (secondCheckInvoice !== 0 && secondCheckInvoice !== undefined)
        ? formatNumber(revenue)
        : null;

    // Render the TableCell component
    return (
      <TableCell className="data-number">
        {(showItemByMonth[index] !== undefined ? showItemByMonth[index] === false : uncheckMonth.includes(month)) ? value : formatNumber(revenue ?? 0)}
      </TableCell>
    );
  };
  const renderInvoiceTableCell = (data, dataFirstCheck, dataSecondCheck, month, index) => {
    if (
      data &&
      (data.status === "no_paid" || data.status === "partially_paid")
    ) {
      return <TableCellInvoice invoice={data} />;
    } else {
      const revenue = data?.invoice_amount;
      const firstCheckRevenue = dataFirstCheck?.revenue;
      const secondCheckInvoice = dataSecondCheck?.revenue;

      // Determine the value based on the conditions
      const value =
        (revenue !== 0 && revenue !== undefined) ||
          (firstCheckRevenue !== 0 && firstCheckRevenue !== undefined) ||
          (secondCheckInvoice !== 0 && secondCheckInvoice !== undefined)
          ? formatNumber(revenue)
          : null;
      return (
        <TableCell
          className="data-number"
          style={
            data?.status === "paid"
              ? { color: "green", backgroundColor: "#F8F8F8", position: 'relative', paddingLeft: '5px' }
              : { backgroundColor: "#F8F8F8", position: 'relative', paddingLeft: '5px' }
          }
        >
          {data?.status_send === 1 && <IconPlane style={{ fill: '#5F89BA', position: 'absolute', top: '3px', left: '3px' }} />}
          {(showItemByMonth[index] !== undefined ? showItemByMonth[index] === false : uncheckMonth.includes(month)) ? value : formatNumber(data?.invoice_amount ?? 0)}
        </TableCell>
      );
    }

  };
  // callback function when the loadMoreRef intersects the viewport
  const handleLoadMoreIntersection = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting && !loadMore && page < lastPage) {
      setLoadMore(true);
      dispatch(incrementPage());
    }   
  };

  // observe the loadMoreRef using IntersectionObserver
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    };
    const observer = new IntersectionObserver(
      handleLoadMoreIntersection,
      options
    );
    if (loadMoreRef.current) observer.observe(loadMoreRef.current);
    
    return () => observer.disconnect();
  }, [loadMore, handleLoadMoreIntersection]);

  const navigateOrder = (orderId) => {
    navigate(`/orders/edit/${orderId}`);
  };
  const navigateContact = (contactId) => {
    navigate(`/contact/${contactId}`);
  };

  const findColorInvoice = (actualInvoice, expectedInvoice) => {
    if (actualInvoice === 0) {
      return 'red';
    } else if (actualInvoice < expectedInvoice) {
      return 'orange';
    } else {
      return 'green';
    }
  };
  const getCurrencyByUnit = (unit) => {
    if(unit === 'jmu') {
      return t('total_revenue_jmu');
    }
    if (unit === 'emu'){
      return t('total_revenue_emu');
    }
    return t('total_revenue');
   };
  const checkShowOrder = (dataOrder, allMonth, monthUncheck) => {
    const formatNumber = n => isNaN(n) ? 0 : n;

    // Filter out unchecked months
    const monthCheck = allMonth.filter(value => !monthUncheck.includes(value));

    // Helper to calculate totals
    const calculateTotal = (dataKey) =>
      monthCheck.reduce((total, month) => total + formatNumber(dataKey === 'invoice_amount' ? dataOrder[dataKey]?.[month]?.invoice_amount : dataOrder[dataKey]?.[month]?.revenue), 0);

    // Calculate totals based on the checked months
    const totalOrder = calculateTotal('order');
    const totalDistribution = calculateTotal('distribution');
    const totalInvoiceAmount = calculateTotal('invoice_amount');

    // Choose whether to use totals or the pre-calculated values based on flags
    const valueOrder = showItemOrder ? (monthCheck.length === 0 ? Number(dataOrder['total_order']) : totalOrder) : 0;
    const valueDistribution = showItemDistribution ? (monthCheck.length === 0 ? Number(dataOrder['total_distribution']) : totalDistribution) : 0;
    const valueInvoiceAmount = showItemInvoice ? (monthCheck.length === 0 ? Number(dataOrder['total_invoice_amount']) : totalInvoiceAmount) : 0;

    // Return whether the sum of the three values is not zero
    return (valueOrder + valueDistribution + valueInvoiceAmount) !== 0;
  };
  const handleCheckboxChange = async (event, index, month) => {
    showItemByMonth[index] = event.target.checked;
    setShowItemByMonth([...showItemByMonth]);
    if (showItemByMonth.length === 12) {
      if (showItemByMonth.includes(true)) {
        setUnCheckAll(false);
      } else {
        setUnCheckAll(true);
      }
    }
    await dispatch(updateUncheckMonthByUser({
      'month': month,
      'status_uncheck': !showItemByMonth[index] ? 1 : 0
    }));
  };
  
  return (
    <>
      {orderRevenue && (
        <div className="order-report-area">
          <TableContainer className="order-report-container no-scroll-y">
            <Table
              stickyHeader
              aria-label="sticky table"
              id="table-order-report"
            >
              <TableHead>
                {showItemOrder && <TableRow className="row-renevue">
                  <TableCell
                    className={"first-column h-37  border-top"}
                    colSpan={2}
                  ></TableCell>
                  {Object.keys(revenueDistribution?.target)?.map(
                    (month, index) => (
                      <TableCell
                        key={`head-cell-${index}`}
                        className={
                          month.includes("Total")
                            ? "total-order-header h-37 border-right data-number"
                            : "data-table border-right data-number"
                        }
                      >
                        {month.includes("Total") ? getCurrencyByUnit(unit) : month}
                      </TableCell>
                    )
                  )}
                </TableRow>}
                {showItemOrder &&
                  Object.keys(orderRevenue).map((key, index) => (
                    <TableRow
                      className={`row-renevue order-revenue ${key}`}
                      key={`body-cell-${index}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={"first-column cell-divider fs-11"}
                        colSpan={2}
                      >
                        {t(FIELD_ORDER_REPORT[key])}
                      </TableCell>
                      {Object.keys(orderRevenue[key]).map((month, index) => (
                        <TableCell
                          key={`body-cell-order-${index}`}
                          className={
                            month.includes("Total")
                              ? "total-order-header cell-divider data-number"
                              : "data-table cell-divider data-number"
                          }
                          style={
                            key.includes("percent_target")
                              ? {
                                color:
                                  orderRevenue[key][month] >= 100
                                    ? "green"
                                    : "red",
                                fontWeight: "bold",
                              }
                              : { fontWeight: "bold" }
                          }
                        >
                          {key.includes("percent_target") &&
                            orderRevenue[key][month] !== "-"
                            ? formatNumber(orderRevenue[key][month]) + "%"
                            : formatNumber(orderRevenue[key][month])}
                          {/* <hr /> */}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                {showItemDistribution && (
                  <>
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={"first-column row-invoice-revenue fw-bold "}
                        colSpan={2}
                      >
                        {t('revenue_distribution')}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        className={"first-column row-invoice-revenue fw-bold"}
                        colSpan={13}
                      ></TableCell>
                    </TableRow>

                  </>
                )}
                {showItemOrder === false && showItemDistribution === true && <TableRow className="row-renevue">
                  <TableCell
                    className={"first-column h-37  border-top"}
                    colSpan={2}
                  ></TableCell>
                  {Object.keys(revenueDistribution?.target)?.map(
                    (month, index) => (
                      <TableCell
                        key={`head-cell-${index}`}
                        className={
                          month.includes("Total")
                            ? "total-order-header h-37 border-right data-date"
                            : "data-table border-right data-date"
                        }
                      >
                        {month}
                      </TableCell>
                    )
                  )}
                </TableRow>}

                {showItemDistribution &&
                  Object.keys(revenueDistribution).map((key, index) => (
                    <TableRow
                      className={`row-renevue revenue-distribution ${key}`}
                      key={`body-cell-${index}`}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={"first-column cell-divider"}
                        colSpan={2}
                      >
                        {t(FIELD_ORDER_REPORT[key])}
                        {/* <hr /> */}
                      </TableCell>
                      {Object.keys(revenueDistribution[key]).map(
                        (month, index) => (
                          <TableCell
                            key={`body-cell-order-${index}`}
                            className={
                              month.includes("Total")
                                ? "total-order-header cell-divider data-number"
                                : "data-table cell-divider data-number"
                            }
                            style={
                              key.includes("percent_target")
                                ? {
                                  color:
                                    revenueDistribution[key][month] >= 100
                                      ? "green"
                                      : "red",
                                  fontWeight: "bold",
                                }
                                : { fontWeight: "bold" }
                            }
                          >
                            {key.includes("percent_target") &&
                              revenueDistribution[key][month] !== "-"
                              ? formatNumber(revenueDistribution[key][month]) +
                              "%"
                              : formatNumber(revenueDistribution[key][month])}
                            {/* <hr /> */}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  ))}
                {showItemInvoice && (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      className={"first-column row-invoice-revenue fw-bold"}
                      colSpan={2}
                    >
                      {t('invoice_revenue')}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      className={"first-column row-invoice-revenue fw-bold"}
                      colSpan={13}
                    ></TableCell>
                  </TableRow>
                )}
                {showItemOrder === false && showItemDistribution === false && showItemInvoice === true && <TableRow className="row-renevue">
                  <TableCell
                    className={"first-column h-37  border-top"}
                    colSpan={2}
                  ></TableCell>
                  {Object.keys(revenueDistribution?.target)?.map(
                    (month, index) => (
                      <TableCell
                        key={`head-cell-${index}`}
                        className={
                          month.includes("Total")
                            ? "total-order-header h-37 border-right data-number"
                            : "data-table border-right data-number"
                        }
                      >
                        {month}
                      </TableCell>
                    )
                  )}
                </TableRow>}
                {showItemInvoice &&
                  Object.keys(invoiceRevenue).map((key, index) => (
                    <TableRow
                      className={`row-renevue invoice-revenue ${key}`}

                      key={key}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        className={"first-column cell-divider"}
                        colSpan={2}
                      >
                        {t(FIELD_ORDER_REPORT[key])}
                      </TableCell>
                      {Object.keys(invoiceRevenue[key]).map((month, index) => (
                        <TableCell
                          key={`body-cell-invoice-${index}`}
                          className={
                            month.includes("total")
                              ? "total-order-header cell-divider data-number"
                              : "data-table cell-divider data-number"
                          }
                          style={{ fontWeight: "bold" }}
                        >
                          {formatNumber(invoiceRevenue[key][month])}
                          <span className="border-bottom"></span>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                <TableRow className="header-table" style={{ height: "17px" }}>
                  <TableCell
                    className={"first-column row-invoice-revenue fw-bold"}
                    colSpan={15}
                  ></TableCell>
                </TableRow>
                <TableRow className="header-table" style={{ position: "sticky", top: 0, zIndex: 4 }}>
                  <TableCell className={"first-column"} colSpan={2}>
                    {t('order')} <span style={{ fontStyle: "italic" }}>{t('order_title_revenue')}</span>
                  </TableCell>
                  {columnMonth.map((month, index) => (
                    <TableCell className="data-date" key={"month" + index}>
                      <div style={{ display: "flex", alignItems: 'center', justifyContent: 'end' }}>
                        <Checkbox size="small" sx={{ padding: '4px' }}
                          checked={showItemByMonth[index] !== undefined ? (showItemByMonth[index] ?? true) : !uncheckMonth.includes(month)}
                          onChange={(e) => handleCheckboxChange(e, index, month)}
                        />
                        {month}
                      </div>
                    </TableCell>
                  ))}
                  <TableCell
                    style={{ right: 0, zIndex: 2 }}
                    className={"total-order data-number"}
                  >
                    {t('total')}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflowX: 'auto' }}>
                {reportOrder === null || reportOrder.length === 0 || Object.keys(reportOrder).length === 0 ? (
                  <tr>
                    <td className={"w-350"}></td>
                    <td colSpan={14} style={{ textAlign: 'center', fontSize: '14px', paddingRight: '350px' }}>{t('no_data')}</td>
                  </tr>
                ) :
                  reportOrder && Object.keys(reportOrder).map((order, rowIndex) => (
                    <>
                      {
                        (checkShowOrder(reportOrder[order], columnMonth, uncheckMonth)) && (
                          <>
                            {showItemOrder && (
                              <TableRow key={"tbody-order-" + rowIndex}>
                                <TableCell
                                  rowSpan={checkedCount}
                                  className={"order-header first-column w-250 fs-11"}
                                  style={
                                    showItemOrder === true
                                      ? {}
                                      : { display: "none" }
                                  }
                                >
                                  <div className="order-information">
                                    <button className="order-name">
                                      <Link className="link-order-navigate" to={`/orders/edit/${reportOrder[order]["id"]}`}>{reportOrder[order]["name"]}</Link>
                                    </button>
                                    <p className="invoice_overview" >
                                      <span style={{ color: findColorInvoice(reportOrder[order]["actual_invoice"], reportOrder[order]["expected_invoice"]) }}>{formatNumber(reportOrder[order]["actual_invoice"])}
                                      </span>
                                      /{formatNumber(reportOrder[order]["expected_invoice"])}
                                    </p>
                                    <button onClick={(e) => navigateContact(reportOrder[order]["contact"]['id'])} className="contact-name">{reportOrder[order]["contact"]['name']}</button>
                                  </div>
                                </TableCell>
                                <TableCell className={"second-column"}>
                                  {t("order")}
                                </TableCell>
                                {Object.keys(reportOrder[order]["order"]).map(
                                  (invoice, colIndex) =>
                                    renderRenevueTableCell(
                                      showItemOrder ? reportOrder[order]["order"][invoice] : null,
                                      showItemDistribution ? reportOrder[order]["distribution"][invoice] : null,
                                      showItemInvoice ? reportOrder[order]["invoice_amount"][invoice] : null,
                                      invoice,
                                      colIndex
                                    )
                                )}
                                <TableCell
                                  className={"total-order data-number"}
                                  style={{ position: "sticky", right: 0, zIndex: 1 }}
                                >
                                  {formatNumber(reportOrder[order]["total_order"])}
                                </TableCell>
                              </TableRow>
                            )}
                            {showItemDistribution && (
                              <TableRow key={rowIndex}>
                                <TableCell
                                  rowSpan={checkedCount}
                                  className={"order-header first-column w-250 fs-11"}
                                  style={
                                    showItemOrder === false &&
                                      showItemDistribution === true
                                      ? {}
                                      : { display: "none" }
                                  }
                                >
                                  <div className="order-information">
                                    <button className="order-name" onClick={(e) => navigateOrder(order)}>
                                      {reportOrder[order]["name"]}
                                    </button>
                                    <p className="invoice_overview" >
                                      <span style={{ color: findColorInvoice(reportOrder[order]["actual_invoice"], reportOrder[order]["expected_invoice"]) }}>{formatNumber(reportOrder[order]["actual_invoice"])}
                                      </span>
                                      /{formatNumber(reportOrder[order]["expected_invoice"])}
                                    </p>
                                    <button onClick={(e) => navigateContact(reportOrder[order]["contact"]['id'])} className="contact-name">{reportOrder[order]["contact"]['name']}</button>
                                  </div>
                                </TableCell>
                                <TableCell className={"second-column"}>
                                  {t("distribution")}
                                </TableCell>
                                {Object.keys(reportOrder[order]["distribution"]).map(
                                  (distribution, colIndex) =>
                                    renderRenevueTableCell(
                                      showItemDistribution ? reportOrder[order]["distribution"][distribution] : null,
                                      showItemOrder ? reportOrder[order]["order"][distribution] : null,
                                      showItemInvoice ? reportOrder[order]["invoice_amount"][distribution] : null,
                                      distribution,
                                      colIndex
                                    )
                                )}
                                <TableCell
                                  className={"total-order data-number"}
                                  style={{ position: "sticky", right: 0, zIndex: 1 }}
                                >
                                  {formatNumber(
                                    reportOrder[order]["total_distribution"]
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                            {showItemInvoice && (
                              <TableRow key={rowIndex + "invoice-data"}>
                                <TableCell
                                  rowSpan={checkedCount}
                                  className={"order-header first-column w-250 fs-11"}
                                  style={
                                    showItemOrder === false &&
                                      showItemDistribution === false &&
                                      showItemInvoice === true
                                      ? {}
                                      : { display: "none" }
                                  }
                                >
                                  <div className="order-information">
                                    <button className="order-name" onClick={(e) => navigateOrder(order)}>
                                      {reportOrder[order]["name"]}
                                    </button>
                                    <p className="invoice_overview" >
                                      <span style={{ color: findColorInvoice(reportOrder[order]["actual_invoice"], reportOrder[order]["expected_invoice"]) }}>{formatNumber(reportOrder[order]["actual_invoice"])}
                                      </span>
                                      /{formatNumber(reportOrder[order]["expected_invoice"])}
                                    </p>
                                    <button onClick={(e) => navigateContact(reportOrder[order]["contact"]['id'])} className="contact-name">{reportOrder[order]["contact"]['name']}</button>
                                  </div>
                                </TableCell>
                                <TableCell
                                  className={"second-column"}
                                  style={{ backgroundColor: "#F8F8F8" }}
                                >
                                  {t("invoice")}
                                </TableCell>
                                {Object.keys(reportOrder[order]["invoice_amount"]).map(
                                  (invoice, colIndex) =>
                                    renderInvoiceTableCell(
                                      showItemInvoice ? reportOrder[order]["invoice_amount"][invoice] : null,
                                      showItemOrder ? reportOrder[order]["order"][invoice] : null,
                                      showItemDistribution ? reportOrder[order]["distribution"][invoice] : null,
                                      invoice,
                                      colIndex
                                    )
                                )}
                                <TableCell
                                  className={"total-order data-number"}
                                  style={{
                                    position: "sticky",
                                    right: 0,
                                    zIndex: 1,
                                    backgroundColor: "#F8F8F8",
                                  }}
                                >
                                  {formatNumber(
                                    reportOrder[order]["total_invoice_amount"]
                                  )}
                                </TableCell>
                              </TableRow>
                            )}
                          </>
                        )
                      }
                    </>
                  ))}
                <div ref={loadMoreRef} style={{ height: "10px" }} />
                {loadMore && <p style={{marginTop: "10px"}}>Loading...</p>}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </>
  );
}
