import { createSlice } from "@reduxjs/toolkit";
import { API } from "../utils/api";
import { getTypeForOption, makeURLSearchParams } from "../utils/helper";
import { createAsyncThunk } from "@reduxjs/toolkit";
import request from "../services/client";
import moment from "moment";
import { t } from "i18next";

const initialState = {
  contacts: [],
  loading: false,
  page: 1,
  total: 0,
  from: 0,
  to: 0,
  limit: 50,
  last_page: 1,
  search_option: {
    stages: [],
    units: [],
    pics: [],
    lists: [],
    createDate: [],
    lastUpdated: [],
    text: [],
    countries: [],
    cities: [],
  },
  idFilter: 0,
  nameFilter: "",
  selectedTab: "all",
  filters: [],
  filterList: [],
  error: null,
  isRemoveUnit: false
};

const contactsSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    getContactsStart: (state) => {
      state.loading = true;
    },
    getContactsSuccess: (state, action) => {
      state.loading = false;
      state.page = action.payload.current_page;
      state.total = action.payload.total;
      state.contacts = action.payload.data;
      state.from = action.payload.from;
      state.to = action.payload.to;
      state.last_page = action.payload.last_page;
    },
    getContactsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateContactStart: (state) => {
      state.loading = true;
    },
    updateContactSuccess: (state, action) => {
      state.loading = false;
      state.page = 1;
      const updatedContact = action.payload;
      // eslint-disable-next-line max-len
      const contactIndex = state.contacts.findIndex(
        (contact) => contact.id === updatedContact.id
      );
      state.contacts[contactIndex] = updatedContact;
    },
    updateContactFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createContactStart: (state) => {
      state.loading = true;
    },
    createContactSuccess: (state, action) => {
      state.loading = false;
      state.contacts.push(action.payload);
    },
    createContactFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteContactStart: (state) => {
      state.loading = true;
    },
    deleteContactSuccess: (state, action) => {
      state.loading = false;
    },
    deleteContactFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateOptionSearch: (state, action) => {
      const data = action.payload;
      const type = data.type ? data.type : getTypeForOption(data.option);
      if (type === "text") {
        state.search_option = {
          ...state.search_option,
          text: data.option ? [data.option] : [],
        };
        return;
      }
      if (type === "lists") {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type]
            .map((item) => item.id)
            .includes(data.option.id)
            ? state.search_option[type].filter(
              (value) => value.id !== data.option.id
            )
            : [...state.search_option[type], data.option],
        };
      } else if (type === "units") {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type].find(
            (item) => item.id === data.option.id
          )
            ? state.search_option[type].filter(
              (value) => value.id !== data.option.id
            )
            : [...state.search_option[type], data.option],
        };
      } else if (type === "pics") {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type].find(
            (item) => item.id === data.option.id
          )
            ? state.search_option[type].filter(
              (value) => value.id !== data.option.id
            )
            : [...state.search_option[type], data.option],
        };
        return;
      } else if (type === "countries") {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type].includes(data.option)
            ? state.search_option[type].filter((value) => value !== data.option)
            : [...state.search_option[type], data.option],
        };
        return;
      } else if (type === "createDate" || type === "lastUpdated") {
        state.search_option = {
          ...state.search_option,
          [type]: [data.option],
        };
        return;
      } else {
        state.search_option = {
          ...state.search_option,
          [type]: state.search_option[type].includes(data.option)
            ? state.search_option[type].filter((value) => value !== data.option)
            : [...state.search_option[type], data.option],
        };
      }
    },
    clearOptionSearch: (state, action) => {
      state.search_option = action.payload;
      state.idFilter = 0;
      state.nameFilter = "";
    },
    deleteOptionSearch: (state, action) => {
      const category = action.payload;

      state.search_option = {
        ...state.search_option,
        [category]: [],
      };
    },
    deleteOptionSearchUnits: (state, action) => {
      const category = action.payload;

      state.search_option = {
        ...state.search_option,
        [category]: [],
      };
      state.isRemoveUnit = true;
    },
    createFilterStart: (state) => {
      state.loading = true;
    },
    getFilterStart: (state) => {
      state.loading = true;
    },
    getFilterListSuccess: (state, action) => {
      state.loading = false;
      state.filterList = action.payload.data;
    },
    getFilterListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    getFilterSuccess: (state, action) => {
      state.loading = false;
      state.search_option = action.payload.dataFilter;
      state.idFilter = action.payload.id;
      state.nameFilter = action.payload.name;
    },
    getFilterFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    createFilterSucess: (state, action) => {
      state.loading = false;
      state.filters = action.payload.data;
    },
    createFilterFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateFilterStart: (state) => {
      state.loading = true;
    },
    updateFilterSucess: (state, action) => {
      state.loading = false;
      state.filters = action.payload;
    },
    updateFilterFailure: (state, action) => {
      state.loading = false;
      state.filters = action.payload;
    },
    deleteFilterSuccess: (state, action) => {
      state.loading = false;
    },
    deleteFilterFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    blackListStart: (state) => {
      state.loading = true;
    },
    addToBlackListSuccess: (state, action) => {
      state.loading = false;
      let listItem = action.payload;
      let toNumber = state.to - listItem.length;
      let total = state.total - listItem.length;
      let contactsFilter = state.contacts.filter(
        (item) => !listItem.includes(item.id)
      );
      state.contacts = contactsFilter;
      state.to = toNumber;
      state.total = total;
    },
    addToBlackListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    removeFromBlackListSuccess: (state, action) => {
      state.loading = false;
      let listItem = action.payload;
      let toNumber = state.to - listItem.length;
      let total = state.total - listItem.length;
      let contactsFilter = state.contacts.filter(
        (item) => !listItem.includes(item.id)
      );
      state.contacts = contactsFilter;
      state.to = toNumber;
      state.total = total;
    },
    removeFromBlackListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    addToListStart: (state) => {
      state.loading = true;
    },
    addToListSuccess: (state, action) => {
      state.loading = false;
    },
    addToListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetError: (state) => {
      state.error = null;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setLimit: (state, action) => {
      state.limit = action.payload;
    },
    updateOptionSearchOfUnit: (state, action) => {
      state.search_option = {
        ...state.search_option,
        units: action.payload.option
      };
    },
  },
});

export const {
  getContactsStart,
  getContactsSuccess,
  getContactsFailure,
  updateContactStart,
  updateContactSuccess,
  updateContactFailure,
  createContactStart,
  createContactSuccess,
  createContactFailure,
  updateOptionSearch,
  deleteOptionSearch,
  getFilterStart,
  getFilterListSuccess,
  getFilterListFailure,
  getFilterSuccess,
  getFilterFailure,
  createFilterStart,
  createFilterSucess,
  createFilterFailure,
  updateFilterStart,
  updateFilterSucess,
  updateFilterFailure,
  deleteFilterSuccess,
  deleteFilterFailure,
  clearOptionSearch,
  blackListStart,
  addToBlackListSuccess,
  addToBlackListFailure,
  removeFromBlackListSuccess,
  removeFromBlackListFailure,
  deleteContactStart,
  deleteContactSuccess,
  deleteContactFailure,
  addToListStart,
  addToListSuccess,
  addToListFailure,
  resetError,
  setSelectedTab,
  setLimit,
  getCountries,
  updateOptionSearchOfUnit,
  deleteOptionSearchUnits
} = contactsSlice.actions;

export const fetchContacts =
  (page = 1, tab) =>
    async (dispatch, getState) => {
      const searchOption = getState().contacts.search_option;
      const limit = getState().contacts.limit;
      try {
        dispatch(getContactsStart());
        const params = {
          page: page,
          flag: tab,
          limit: limit,
        };

        if (searchOption) {
          const {
            stages,
            units,
            pics,
            lists,
            createDate,
            lastUpdated,
            text,
            countries,
            cities
          } = searchOption;
          if (stages?.length > 0) {
            const formattedStages = stages.map((stage) =>
              stage.replace(/ /g, "_")
            );
            params.stage = formattedStages.join(",").toLowerCase();
          }
          if (units?.length > 0) {
            params.unit = units
              .map((unit) => unit.id)
              .join(",")
              .toLowerCase();
          }
          if (pics?.length > 0) {
            params.user = pics
              .map((pic) => pic.id)
              .join(",")
              .toLowerCase();
          }
          if (countries?.length > 0) {
            params.countries = countries
              .map((country) => country)
              .join(",")
              .toLowerCase();
          }
          if (cities?.length > 0) {
            params.cities = cities
              .map((city) => city)
              .join(",")
              .toLowerCase();
          }
          if (lists?.length > 0) {
            params.contact_list_id = lists
              .map((item) => item.id)
              .join(",")
              .toLowerCase();
          }
          if (createDate?.length > 0) {
            params.create_date_start = createDate[0].startDate;
            params.create_date_end = createDate[0].endDate;
          }
          if (lastUpdated?.length > 0) {
            params.update_date_start = lastUpdated[0].startDate;
            params.update_date_end = lastUpdated[0].endDate;
          }
          if (text?.length > 0) {
            params.search = text.join(",").toLowerCase();
          }
        }
        const query = makeURLSearchParams(params);
        const url = `${API.listContacts}${query}`;
        const response = await request(url);
        const responseJson = await response.json();
        dispatch(getContactsSuccess(responseJson));
      } catch (error) {
        dispatch(getContactsFailure(error.message));
      }
    };

export const updateContact = (contact) => async (dispatch) => {
  try {
    contact.contact_form_url = contact["contact_form_url"];
    delete contact["contact form url"];
    contact.company_classification =
      contact.companyclassification ??
      contact.company_classification?.id ??
      contact.company_classification;
    delete contact["companyclassification"];
    contact.industry = contact.industry?.id ?? contact.industry;
    contact.unit = contact?.unit?.id ?? contact?.unit;
    contact.marketer = contact?.marketer?.id ?? contact?.marketer;
    contact.saler = contact?.saler?.id ?? contact?.saler;
    contact.am = contact?.am?.id ?? contact?.am;
    contact.team = contact?.team?.id ?? contact?.team;
    contact.channel = contact?.channel?.id ?? contact?.channel;
    contact.mql_date =
      contact?.mql_date &&
      new Date(contact?.mql_date).toLocaleDateString("en-ZA");
    contact.sql_date =
      contact?.sql_date &&
      new Date(contact?.sql_date).toLocaleDateString("en-ZA");
    dispatch(updateContactStart());
    const response = await request(API.updateContact, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contact),
    });
    const updatedContact = await response.json();
    if (response.status !== 200) {
      dispatch(createContactFailure(updatedContact.error?.website[0]));
    } else {
      dispatch(createContactSuccess(updatedContact));
      dispatch(createContactFailure(""));
    }
    return updatedContact;
  } catch (error) {
    dispatch(updateContactFailure(error.message));
    return false;
  }
};

export const updateContactInTodoStatus = (contact) => async (dispatch) => {
  try {
    contact.contact_form_url = contact["contact form url"];
    delete contact["contact form url"];
    contact.company_classification =
      contact["company classification"] ?? contact.company_classification;
    delete contact["company classification"];
    dispatch(updateContactStart());
    const response = await request(API.updateContactInTodoStatus, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(contact),
    });
    const updatedContact = await response.json();
    if (response.status !== 200) {
      dispatch(createContactFailure(updatedContact.error?.website[0]));
    } else {
      dispatch(createContactSuccess(updatedContact));
      dispatch(createContactFailure(""));
    }
    return updatedContact;
  } catch (error) {
    dispatch(updateContactFailure(error.message));
    return false;
  }
};

export const updateStage =
  ({ contact, contactListId, newStage }) =>
    async (dispatch) => {
      try {
        dispatch(updateContactStart());
        const response = await request(API.updateContactStage, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            contact_id: contact.id,
            contact_list_id: contactListId,
            stage: newStage,
          }),
        });
        if (!response.ok) {
          throw new Error("Update stage failed.");
        }
        contact.stage = newStage;
        dispatch(updateContactSuccess(contact));
        return true;
      } catch (error) {
        dispatch(updateContactFailure(error.message));
        return false;
      }
    };

export const createContact = (contact) => async (dispatch) => {
  try {
    contact.contact_form_url = contact.contactformurl;
    delete contact.contactformurl;
    contact.company_classification = contact.companyclassification;
    delete contact.companyclassification;
    contact.stage = contact.stage?.replace(/\s+/g, "_").toLowerCase() ?? "iql";
    contact.mql_date =
      contact?.mql_date &&
      new Date(contact?.mql_date).toLocaleDateString("en-ZA");
    contact.sql_date =
      contact?.sql_date &&
      new Date(contact?.sql_date).toLocaleDateString("en-ZA");
    dispatch(createContactStart());
    const response = await request(`${API.createContact}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...contact }),
    });

    const createdContact = await response.json();
    if (response.status !== 200) {
      dispatch(createContactFailure(createdContact.error?.website[0]));
    } else {
      dispatch(createContactSuccess(createdContact));
      dispatch(createContactFailure(""));
    }
  } catch (error) {
    dispatch(createContactFailure(error.message));
  }
};

export const fetchFilterList = (type) => async (dispatch) => {
  try {
    dispatch(getFilterStart());
    let url = `${API.listFilter}${makeURLSearchParams({ type })}`;

    const response = await request(url);
    const responseJson = await response.json();
    dispatch(getFilterListSuccess(responseJson));
  } catch (error) {
    dispatch(getFilterListFailure(error.message));
  }
};

export const getFilterById = (id) => async (dispatch, getState) => {
  let dataFilter = {};
  try {
    const units = getState().units.data;
    dispatch(getFilterStart());
    let url = `${API.showFilter}${makeURLSearchParams({ id })}`;
    const response = await request(url);
    const responseJson = await response.json();

    for (let key in responseJson.data.data) {
      if (responseJson.data.data[key].length > 0) {
        dataFilter[key] = responseJson.data.data[key].map((value) => {
          switch (key) {
            case "text":
            case "lists":
              return value;
            case "units":
              return units.find((unit) => unit.id == value) ?? null;
            case "createDate":
              return {
                startDate: moment(value.startDate),
                endDate: moment(value.endDate),
              };
            case "lastUpdated":
              return {
                startDate: moment(value.startDate),
                endDate: moment(value.endDate),
              };
            default:
              return value.length > 3
                ? value.charAt(0).toUpperCase() +
                value.slice(1).replace("_", " ")
                : value.toUpperCase();
          }
        });
      } else {
        dataFilter[key] = [];
      }
    }
    const data = {
      id: id,
      name: responseJson.data.name,
      dataFilter: dataFilter,
    };

    dispatch(getFilterSuccess(data));
  } catch (error) {
    dispatch(getFilterFailure(error.message));
  }
};

export const CreateNewFilter = (filter) => async (dispatch) => {
  let dataFilter = {};
  for (let key in filter.filterValue) {
    dataFilter[key] = filter.filterValue[key].map((value) =>
      typeof value === "string"
        ? value.replace(/\s+/g, "_").toLowerCase()
        : value?.id || {
          startDate: value.startDate.toDate(),
          endDate: value.endDate.toDate(),
        }
    );
  }
  let body = {
    type: filter?.type,
    name: filter?.inputValues?.Name,
    data: dataFilter,
  };
  try {
    dispatch(createFilterStart(body));
    const response = await request(`${API.storeFilter}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const createdFilter = await response.json();
    dispatch(createFilterSucess(createdFilter));
  } catch (error) {
    dispatch(createFilterFailure(error.message));
  }
};

export const UpdateFilter = (filter) => async (dispatch) => {
  let dataFilter = {};
  for (let key in filter.filterValue) {
    dataFilter[key] = filter.filterValue[key].map((value) =>
      typeof value === "string"
        ? value.replace(/\s+/g, "_").toLowerCase()
        : value?.id || { startDate: value.startDate, endDate: value.endDate }
    );
  }
  let body = {
    id: filter?.idFilter,
    data: dataFilter,
  };
  try {
    dispatch(updateFilterStart());
    const response = await request(`${API.updateFilter}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const updateFilter = await response.json();
    dispatch(updateFilterSucess(updateFilter));
  } catch (error) {
    dispatch(updateFilterFailure(error.message));
  }
};

export const RemoveFilter = (id) => async (dispatch) => {
  try {
    dispatch(getFilterStart());
    let url = `${API.deleteFilter}?id=${id}`;
    const response = await request(url, {
      method: "DELETE",
    });
    const responseJson = await response.json();

    dispatch(deleteFilterSuccess(responseJson));
  } catch (error) {
    dispatch(deleteFilterFailure(error.message));
  }
};

export const importContactsByCsv = createAsyncThunk(
  "contacts/importCsv",
  async ({ file, name }) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("name", name);

      const response = await request(`${API.importCsv}`, {
        method: "POST",
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`The file format is not correct for contact data.`);
      }

      const result = await response.json();
      return result;
    } catch (error) {
      throw error;
    }
  }
);

export const selectSearch = (option, type) => async (dispatch) => {
  const data = {
    option,
    type,
  };
  try {
    dispatch(updateOptionSearch(data));
  } catch (error) {
    //
  }
};

export const updateUnitForContact = (option) => async (dispatch) => {
  const data = {
    option
  };
  try {
    dispatch(updateOptionSearchOfUnit(data));
  } catch (error) {
    //
  }
};

export const clearSearch = (data) => async (dispatch) => {
  try {
    dispatch(clearOptionSearch(data));
  } catch (error) {
    //
  }
};

export const removeSearch = (category) => async (dispatch) => {
  try {
    dispatch(deleteOptionSearch(category));
  } catch (error) {
    //
  }
};

export const removeSearchUnits = (category) => async (dispatch) => {
  try {
    dispatch(deleteOptionSearchUnits(category));
  } catch (error) {
    //
  }
};

export const getContactById = (id) => async (dispatch, getState) => {
  try {
    let url = `${API.getContactById}${makeURLSearchParams({ id })}`;
    const response = await request(url);
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      const contact = responseJson.data;
      return contact;
    }
    return null;
  } catch (error) {
    //
  }
};

export const addToBlackList = (contacts) => async (dispatch, getState) => {
  try {
    dispatch(blackListStart());
    let url = `${API.addToBlackList}?`;
    let list = contacts.join(",");
    const response = await request(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ contacts: list }),
    });
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      dispatch(addToBlackListSuccess(contacts));
      return true;
    } else {
      dispatch(addToBlackListFailure(responseJson.error));
      return false;
    }
  } catch (error) {
    dispatch(addToBlackListFailure(error.message));
    return false;
  }
};

export const removeFromBlackList = (contacts) => async (dispatch) => {
  try {
    dispatch(blackListStart());
    let url = `${API.removeFromBlacklist}?`;
    let list = contacts.join(",");
    const response = await request(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ contacts: list }),
    });
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      dispatch(removeFromBlackListSuccess(contacts));
      return true;
    } else {
      dispatch(removeFromBlackListFailure(responseJson.error));
      return false;
    }
  } catch (error) {
    dispatch(removeFromBlackListFailure(error.message));
    return false;
  }
};

export const deleteContacts = (contactId) => async (dispatch) => {
  try {
    dispatch(deleteContactStart());
    let list = contactId.join(",");
    let url = `${API.deleteContact}/destroy`;
    const response = await request(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ contacts: list }),
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    dispatch(deleteContactFailure(error.message));
    return false;
  }
};

export const getContactsForInput =
  (value, flag = "") =>
    async (dispatch, getState) => {
      try {
        let url = `${API.getContactsForInput}${makeURLSearchParams({
          search: value,
          flag: flag,
        })}`;
        const response = await request(url);
        const responseJson = await response.json();
        if (responseJson.status_code === 200) {
          return responseJson;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    };

export const addContactsToList = createAsyncThunk(
  "contacts/addTolist",
  async (data, { dispatch }) => {
    try {
      dispatch(addToListStart());
      const dataAppend = new FormData();
      if (data.contact_ids) {
        if (Array.isArray(data.contact_ids)) {
          data.contact_ids.forEach((item) => dataAppend.append("contact_ids[]", item))
        } else {
          dataAppend.append("contact_ids[]", data.contact_ids)
        }
      }
      if (data.file_exclude) {
        dataAppend.append("file_exclude", data.file_exclude);
      }
      if (data.list_exclude) {
        data.list_exclude.forEach((item) => dataAppend.append("list_exclude[]", item))
      }
      if (data.name) {
        dataAppend.append("name", data.name);
      }
      if (data.option) {
        dataAppend.append("option", data.option);
      }
      if (data.search) {
        dataAppend.append("search", JSON.stringify(data.search));
      }
      if (data.source) {
        dataAppend.append("source", data.source);
      }
      dataAppend.append("type", data.type);
      if (data.unit_id) {
        dataAppend.append("unit_id", data.unit_id ?? null);
      }
      if (data.contact_list_id) {
        dataAppend.append("contact_list_id", data.contact_list_id);
      }
      const response = await request(`${API.addContactToList}`, {
        method: "POST",
        body: dataAppend,
      });

      if (response.ok === true) {
        const result = await response.blob();
        dispatch(addToListSuccess(result));
        return true;
      } else {
        dispatch(
          addToListFailure(
            response.error.name
              ? response.error.name[0]
              : response.error
          )
        );
        return false;
      };
    } catch (error) {
      dispatch(addToListFailure(error.message));
      return false;
    }
  }
);
/* export const addContactsToList = (formData) => async (dispatch) => {
  try {
    dispatch(addToListStart());
    const dataAppend = new FormData();
    dataAppend.append("contact_ids", formData.contact_ids);
    dataAppend.append("file_exclude", formData.file_exclude);
    dataAppend.append("list_exclude", formData.list_exclude);
    dataAppend.append("name", formData.name);
    dataAppend.append("option", formData.option);
    dataAppend.append("search", formData.search);
    dataAppend.append("source", formData.source);
    dataAppend.append("type", formData.type);
    dataAppend.append("unit_id", formData.unit_id);
    const response = await request(`${API.addContactToList}`, {
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      body: dataAppend,
    });
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      dispatch(addToListSuccess(responseJson));
      return true;
    } else {
      dispatch(
        addToListFailure(
          responseJson.error.name
            ? responseJson.error.name[0]
            : responseJson.error
        )
      );
      return false;
    }
  } catch (error) {
    dispatch(addToListFailure(error.message));
    return false;
  }
}; */

export const setSelectTab = (data) => async (dispatch) => {
  try {
    dispatch(setSelectedTab(data));
  } catch (error) {
    //
  }
};

export const setPageSize = (data) => async (dispatch) => {
  try {
    dispatch(setLimit(data));
  } catch (error) {
    //
  }
};

export const exportContactsFromLists = createAsyncThunk(
  "contacts/exportContactsFromLists",
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("is_export_all", data?.isExportAll ? 1 : 0);
      if ("byListIds" in data) {
        for (let i = 0; i < data.byListIds.length; i++) {
          formData.append(`by_list_ids[${i}]`, data.byListIds[i]);
        }
      }
      if ("searchOption" in data) {
        const { units, createDate, lastUpdated, text } = data.searchOption;

        if (units.length > 0) {
          formData.append(
            "unit_id",
            units
              .map((unit) => unit.id)
              .join(",")
              .toLowerCase()
          );
        }
        if (createDate?.length > 0) {
          formData.append("create_date_start", createDate[0].startDate);
          formData.append("create_date_end", createDate[0].endDate);
        }
        if (lastUpdated?.length > 0) {
          formData.append("update_date_start", lastUpdated[0].startDate);
          formData.append("update_date_end", lastUpdated[0].endDate);
        }
        if (text?.length > 0) {
          formData.append("search", text.join(",").toLowerCase());
        }
      }

      const response = await request(`${API.exportContactsFromLists}`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const result = await response.blob();
        return result;
      }

      const newError = new Error(t("Export contacts failed."));
      throw newError;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const exportContacts = createAsyncThunk(
  "contacts/exportContacts",
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("is_export_all", data?.isExportAll ? 1 : 0);
      formData.append(`by_list_id`, data.byListId);
      if ("byIds" in data) {
        for (let i = 0; i < data.byIds.length; i++) {
          formData.append(`by_ids[${i}]`, data.byIds[i]);
        }
      }

      if ("searchOption" in data) {
        const { types, createDate, lastUpdated, text } = data.searchOption;

        if (types.length > 0) {
          formData.append(
            "types",
            types
              .map((type) => type.id)
              .join(",")
              .toLowerCase()
          );
        }
        if (createDate?.length > 0) {
          formData.append("create_date_start", createDate[0].startDate);
          formData.append("create_date_end", createDate[0].endDate);
        }
        if (lastUpdated?.length > 0) {
          formData.append("update_date_start", lastUpdated[0].startDate);
          formData.append("update_date_end", lastUpdated[0].endDate);
        }
        if (text?.length > 0) {
          formData.append("search", text.join(",").toLowerCase());
        }
      }

      const response = await request(`${API.exportContacts}`, {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        const result = await response.blob();
        return result;
      }

      const newError = new Error(t("Export contacts failed."));
      throw newError;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const exportDetailContacts = createAsyncThunk(
  "contacts/exportDetailContacts",
  async (data, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append("is_export_all", data?.isExportAll ? 1 : 0);
      formData.append("is_contact", data?.isContacts ? 1 : 0);
      formData.append("is_backList", data?.isBackList ? 1 : 0);
      formData.append("is_mycontact", data?.isMyContact ? 1 : 0);
      if ("byIds" in data) {
        for (let i = 0; i < data.byIds.length; i++) {
          formData.append(`by_ids[${i}]`, data.byIds[i]);
        }
      }

      if ("searchOption" in data) {
        const { units, lists, stages, createDate, lastUpdated, text } =
          data.searchOption;

        if (units.length > 0) {
          formData.append(
            "unit",
            units
              .map((unit) => unit.id)
              .join(",")
              .toLowerCase()
          );
        }
        if (lists.length > 0) {
          formData.append(
            "list",
            lists
              .map((list) => list.id)
              .join(",")
              .toLowerCase()
          );
        }
        if (stages.length > 0) {
          formData.append(
            "stage",
            stages
              .map((stage) => stage.replace(" ", "_"))
              .join(",")
              .toLowerCase()
          );
        }
        if (createDate?.length > 0) {
          formData.append("create_date_start", createDate[0].startDate);
          formData.append("create_date_end", createDate[0].endDate);
        }
        if (lastUpdated?.length > 0) {
          formData.append("update_date_start", lastUpdated[0].startDate);
          formData.append("update_date_end", lastUpdated[0].endDate);
        }
        if (text?.length > 0) {
          formData.append("search", text.join(",").toLowerCase());
        }
      }

      const response = await request(`${API.exportDetailContacts}`, {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        const result = await response.blob();
        return result;
      }

      const newError = new Error(t("Export contacts failed."));
      throw newError;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const getContactNumberExport = createAsyncThunk(
  "contacts/getContactNumberExport",
  async (data, { rejectWithValue }) => {
    try {
      let query = "";

      Object.entries(data).forEach(([key, values]) => {
        query += `&${encodeURIComponent(
          `${key}[is_export_all]`
        )}=${encodeURIComponent(values?.isExportAll ? 1 : 0)}`;

        if ("byListIds" in values) {
          for (let i = 0; i < values.byListIds.length; i++) {
            query += `&${encodeURIComponent(
              `${key}[by_list_ids][${i}]`
            )}=${encodeURIComponent(values.byListIds[i])}`;
          }
        }
        if ("searchOption" in values) {
          const { units, createDate, lastUpdated, text } = values.searchOption;

          if (units.length > 0) {
            query += `&${encodeURIComponent(
              `${key}[unit_id]`
            )}=${encodeURIComponent(
              units
                .map((unit) => unit.id)
                .join(",")
                .toLowerCase()
            )}`;
          }
          if (createDate?.length > 0) {
            query += `&${encodeURIComponent(
              `${key}[create_date_start]`
            )}=${encodeURIComponent(createDate[0].startDate)}`;
            query += `&${encodeURIComponent(
              `${key}[create_date_end]`
            )}=${encodeURIComponent(createDate[0].endDate)}`;
          }
          if (lastUpdated?.length > 0) {
            query += `&${encodeURIComponent(
              `${key}[update_date_start]`
            )}=${encodeURIComponent(lastUpdated[0].startDate)}`;
            query += `&${encodeURIComponent(
              `${key}[update_date_end]`
            )}=${encodeURIComponent(lastUpdated[0].endDate)}`;
          }
          if (text?.length > 0) {
            query += `&${encodeURIComponent(
              `${key}[search]`
            )}=${encodeURIComponent(text.join(",").toLowerCase())}`;
          }
        }
      });
      query = `?${query}`;

      const response = await request(API.getExportCount + query);

      if (response.ok) {
        const result = await response.json();
        return result;
      }

      const newError = new Error(t("Get contact number export failed."));
      throw newError;
    } catch (error) {
      return handleRejectWithValue(error, rejectWithValue);
    }
  }
);

export const getContactsByDealId = (id) => async (dispatch, getState) => {
  try {
    let url = `${API.getContactsByDealId}${makeURLSearchParams({ id })}`;
    const response = await request(url);
    const responseJson = await response.json();
    if (responseJson.status_code === 200) {
      const contact = responseJson.data;
      return contact;
    }
    return null;
  } catch (error) {
    //
  }
};
export const updatePIC = (data) => async (dispatch, getState) => {
  try {
    let url = `${API.updatePIC}`;
    const response = await request(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ ...data }),
    });
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    return false;
  }
};

function handleRejectWithValue(error, rejectWithValue) {
  const err = { message: error.message };
  if ("messagesResponse" in error) {
    err.messagesResponse = { ...error.messagesResponse };
  }
  return rejectWithValue(err);
}

export default contactsSlice;
